import { File } from "lucide-react"
import { useMemo } from "react"

import { FileIcon } from "~/components/FileIcon"
import { dataAttrs } from "~/nextui"
import _ from "~/tokens"

import { MultipleSelect } from "../../components/Select"
import { count } from "../../toolbar/functions"
import { triggerStyle } from "../../toolbar/style"
import type { Har } from "../har"

const knownAlias = new Map(
  Object.entries({
    CSS: ["text/css"],
    Favicon: ["image/vnd.microsoft.icon"],
    GIF: ["image/gif"],
    HTML: ["text/html"],
    JavaScript: ["application/javascript", "application/x-javascript", "text/javascript"],
    JPEG: ["image/jpeg"],
    JSON: ["application/json"],
    PDF: ["application/pdf"],
    PNG: ["image/png"],
    SVG: ["image/svg+xml"],
    Text: ["text/plain"],
    Unknown: ["application/octet-stream", "x-unknown"],
    WOFF2: ["font/woff2", "application/font-woff2"],
  }).flatMap(([key, list]) => list.map(v => [v, key] as const))
)

export function MimeTypeFilter({
  har,
  value,
  onChange,
  placeholder = "Mime Type",
}: {
  har?: Har
  value: string[]
  onChange: (value: React.SetStateAction<string[]>) => void
  placeholder?: string
}) {
  const counts = useMemo(
    () =>
      count(
        har?.log.entries.filter(x => x.response.content.mimeType),
        e => e.response.content.mimeType!.split(";")[0]
      ),
    [har]
  )

  return (
    <MultipleSelect
      aria-label="Mime Type"
      placeholder={placeholder}
      size="sm"
      value={value}
      variant="flat"
      classNames={{
        trigger: triggerStyle,
      }}
      options={Array.from(counts)
        .sort((a, b) => b[1] - a[1])
        .map(([key, count]) => ({
          key,
          textValue: key,
          label: (
            <span>
              {knownAlias.has(key) ? knownAlias.get(key) : key}
              <span css="opacity-50">{` (${count})`}</span>
            </span>
          ),
          description: knownAlias.has(key) ? (
            <div css="text-xs opacity-50">{key}</div>
          ) : undefined,
          icon: (
            <FileIcon
              className="w-4"
              fallback={<File css="ml-0.5 opacity-80" size={16} />}
              mimeType={key}
            />
          ),
        }))}
      onChange={onChange}
    />
  )
}

export function SelectButton({
  selected,
  value,
  children,
  onChange,
}: {
  value: string
  selected: string[]
  children: React.ReactNode
  onChange: (value: React.SetStateAction<string[]>) => void
}) {
  const isSelected = useMemo(() => selected.includes(value), [selected, value])

  return (
    <button
      className="ml-1 text-sm hover:underline selected:font-semibold"
      {...dataAttrs({ isSelected })}
      type="button"
      onClick={() =>
        isSelected
          ? onChange(selected.filter(v => v !== value))
          : onChange([...selected, value])
      }
    >
      {children}
    </button>
  )
}
