import { memo, useMemo } from "react"

import { InspectorContext } from "./src/Context"
import { DOMInspector, type DOMInspectorProps } from "./src/DOM"
import { ObjectInspector, type ObjectInspectorProps } from "./src/Object"
import { TableInspector, type TableInspectorProps } from "./src/Table"

function isDOM(value: any): value is Node {
  return value instanceof Node
  // return typeof value?.nodeType === "number" && typeof value.nodeName === "string"
}

type InspectorProps =
  | (TableInspectorProps & { table: true })
  | (ObjectInspectorProps & { table: false })

const InternalInspector = memo<InspectorProps>(({ table = false, ...rest }) => {
  if (table) {
    return <TableInspector {...(rest as TableInspectorProps)} />
  } else if (isDOM(rest.data)) {
    return <DOMInspector {...(rest as DOMInspectorProps)} />
  }
  return <ObjectInspector {...(rest as ObjectInspectorProps)} />
})

export const Inspector = memo<InspectorProps & InspectorContext>(props => {
  const { selected, setSelected, ...rest } = props
  const value: InspectorContext = useMemo(
    () => ({ selected, setSelected }),
    [selected, setSelected]
  )

  return (
    <InspectorContext.Provider value={value}>
      <InternalInspector {...rest} />
    </InspectorContext.Provider>
  )
})
