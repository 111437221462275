import { tw } from "@aet/tailwind/macro"
import { Languages } from "lucide-react"

import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "nextui"

const LocaleIcon = tw.div`w-4 text-center font-mono`

export function LocaleSwitch() {
  return (
    <Dropdown>
      <DropdownTrigger>
        <Button disableRipple isIconOnly variant="light">
          <Languages size={20} />
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        disallowEmptySelection
        aria-label="Locale switch"
        disabledKeys={["fr", "zh-HK"]}
        selectedKeys={["en"]}
        selectionMode="single"
        onSelectionChange={_id => {}}
      >
        <DropdownItem key="en" lang="en" startContent={<LocaleIcon>en</LocaleIcon>}>
          English
        </DropdownItem>
        <DropdownItem key="fr" lang="fr" startContent={<LocaleIcon>fr</LocaleIcon>}>
          Français
        </DropdownItem>
        <DropdownItem key="zh-HK" lang="zh-HK" startContent={<LocaleIcon>zh</LocaleIcon>}>
          繁體中文 (香港)
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
