import { type FileTypeResult, fileTypeFromBuffer } from "file-type"
import { useEffect, useMemo, useState } from "react"

import { fromBase64 } from "../fromBase64"

export function useOctetStream(text: string, skip: boolean) {
  const [fileType, setFileType] = useState<FileTypeResult>()
  const buffer = useMemo(
    () => (skip ? new ArrayBuffer(0) : fromBase64(text)),
    [text, skip]
  )

  useEffect(() => {
    if (!skip) {
      void fileTypeFromBuffer(buffer).then(setFileType)
    }
  }, [buffer, skip])

  return {
    fileType,
    buffer,
  }
}
