import { css } from "@emotion/css"
import { useMemo } from "react"

import { Select, SelectItem, type SelectProps } from "nextui"
import { getSelectionKey } from "~/utils"

type BaseSelectProps<T extends object> = Pick<
  SelectProps<T>,
  | "variant"
  | "className"
  | "classNames"
  | "placeholder"
  | "startContent"
  | "label"
  | "labelPlacement"
  | "size"
>
type SelectOption<V extends string> = {
  key: V
  icon?: React.ReactNode
  label: string
  description?: React.ReactNode
}

function renderOption<V extends string>({
  key,
  icon,
  label,
  description,
  ...rest
}: SelectOption<V>) {
  return (
    <SelectItem key={key} startContent={icon} textValue={label} value={key} {...rest}>
      {description != null ? (
        <>
          <div>{label}</div>
          <div>{description}</div>
        </>
      ) : (
        label
      )}
    </SelectItem>
  )
}

export function SingleSelect<T extends object, V extends string>({
  variant,
  value,
  onChange,
  options,
  classNames,
  startContent,
  ...rest
}: BaseSelectProps<T> & {
  "aria-label": string
  value?: V
  onChange: (value: V) => void
  options: SelectOption<V>[]
}) {
  const selectedIcon = useMemo(
    () => options.find(o => o.key === value)?.icon,
    [value, options]
  )

  return (
    <Select
      selectedKeys={value ? [value] : undefined}
      selectionMode="single"
      startContent={startContent ?? selectedIcon ?? null}
      variant={variant}
      classNames={{
        trigger: variant === "bordered" ? css({ borderWidth: 1 }) : undefined,
        ...classNames,
      }}
      onSelectionChange={id => onChange(getSelectionKey(id) as V)}
      {...rest}
    >
      {options.map(renderOption)}
    </Select>
  )
}

export function MultipleSelect<T extends object, V extends string>({
  variant,
  value,
  onChange,
  options,
  classNames,
  startContent,
  ...rest
}: BaseSelectProps<T> & {
  "aria-label": string
  value: V[]
  onChange: (value: React.SetStateAction<V[]>) => void
  options: SelectOption<V>[]
}) {
  const selectedIcon = useMemo(
    () => options.find(o => o.key === value[0])?.icon,
    [value, options]
  )

  return (
    <Select
      selectedKeys={value}
      selectionMode="multiple"
      startContent={startContent ?? selectedIcon ?? null}
      variant={variant}
      classNames={{
        trigger: variant === "bordered" ? css({ borderWidth: 1 }) : undefined,
        ...classNames,
      }}
      onSelectionChange={id => {
        onChange([...(id as Set<V>)])
      }}
      {...rest}
    >
      {options.map(renderOption)}
    </Select>
  )
}
