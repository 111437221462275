import { cx } from "@emotion/css"
import clsx from "clsx"
import { Search } from "lucide-react"
import { useMemo, useState } from "react"

import { Chip, Input, Listbox, ListboxItem } from "nextui"

import { FileIcon } from "../../components/FileIcon"
import _ from "../../tokens"
import { getSelectionKey, id } from "../../utils"
import type { Entry } from "../har"

import { getMethodColor, getStatusColor } from "./StatusChip"
import { URLView } from "./URLView"

export function EntityList({
  selected,
  setSelected,
  entries,
}: {
  selected: string | undefined
  setSelected: (id: string) => void
  entries: Entry[]
}) {
  const [search, setSearch] = useState("")

  const filtered = useMemo(() => {
    if (!search) {
      return entries
    }

    return entries.filter(entry =>
      entry.request.url.toLowerCase().includes(search.toLowerCase())
    )
  }, [entries, search])

  return (
    <>
      <div className="mt-1 w-full p-1">
        <div css="mx-2">
          <Input
            css="w-full"
            placeholder="Search"
            startContent={<Search size={19} />}
            type="search"
            value={search}
            variant="bordered"
            classNames={{
              inputWrapper: cx(_.bg_white.border._, "dark:bg-black"),
            }}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div css="ml-1.5 mr-1 overflow-scroll">
        <Listbox
          aria-label="Request list"
          items={filtered}
          selectedKeys={selected ? [selected] : []}
          selectionMode="single"
          onSelectionChange={id => setSelected(getSelectionKey(id))}
        >
          {entry => (
            <ListboxItem
              aria-label={entry.request.url}
              key={id(entry)}
              role="listitem"
              className={clsx(
                "selected:bg-gray-200 dark:selected:bg-gray-800 [&>span]:overflow-hidden",
                _.rounded.px[2].py[1].transition_all._
              )}
            >
              <div css="flex items-center gap-1 overflow-hidden">
                <Chip
                  color={getStatusColor(entry.response.status)}
                  css="tabular-nums"
                  radius="sm"
                  size="sm"
                  variant="flat"
                >
                  {entry.response.status}
                </Chip>
                {entry.request.method !== "GET" && (
                  <Chip
                    color={getMethodColor(entry.request.method)}
                    css="tabular-nums"
                    radius="sm"
                    size="sm"
                    variant="flat"
                  >
                    {entry.request.method}
                  </Chip>
                )}
                <FileIcon mimeType={entry.response.content.mimeType} />
                <URLView url={entry.request.url} />
              </div>
            </ListboxItem>
          )}
        </Listbox>
      </div>
    </>
  )
}
