import { createElement } from "react"

import { JavaScript } from "~/components/FileIcon"
import { Accordion, AccordionItem } from "~/nextui"

import type { Initiator, StackParent } from "../har"

function* stackFrame(stack: StackParent, i = 0): Generator<React.ReactElement> {
  if (stack.callFrames.length) {
    const first = stack.callFrames[0]
    yield (
      <AccordionItem
        key={i}
        textValue={(stack.description ?? first?.functionName) || "<anonymous>"}
        classNames={{
          title: "text-sm",
          trigger: "py-1",
        }}
        title={
          stack.description || (
            <div css="flex items-center gap-2">
              {JavaScript}{" "}
              {first?.functionName ? (
                `${first.functionName}()`
              ) : (
                <span css="opacity-50">{"<anonymous>"}</span>
              )}
            </div>
          )
        }
      >
        <ol>
          {stack.callFrames.map((f, i) => (
            <li css="font-mono text-sm" key={i}>
              {f.functionName || <span css="opacity-50">{"<anonymous>"}</span>}{" "}
              <span>
                <span css="opacity-30">(</span>
                <a
                  css="text-blue-950 hover:underline dark:text-blue-100"
                  href={f.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {f.url}
                </a>
                <span css="opacity-50">:</span>
                <wbr />
                {f.lineNumber}:<wbr />
                {f.columnNumber}
                <span css="opacity-30">)</span>
              </span>
            </li>
          ))}
        </ol>
      </AccordionItem>
    )
  }
  if (stack.parent != null) {
    yield* stackFrame(stack.parent, i + 1)
  }
}

export function InitiatorView({ initiator }: { initiator: Initiator }) {
  const header = (
    <h3 css="mb-2 ml-1 text-sm font-medium uppercase tracking-widest opacity-50">
      Initiator
    </h3>
  )

  if (
    initiator.type === "parser" ||
    (initiator.type === "script" && !("stack" in initiator))
  ) {
    return (
      <div css="mb-6">
        {header}
        <div css="rounded-large bg-content1 shadow-small break-words px-6 py-4 text-[0.95rem]">
          {initiator.url}{" "}
          {initiator.lineNumber != null && <span>(Line {initiator.lineNumber})</span>}
        </div>
      </div>
    )
  } else if (initiator.type === "script") {
    return (
      <div css="mb-6">
        {header}
        <div css="rounded-large bg-content1 shadow-small px-4 py-2">
          {createElement(Accordion, {
            items: [],
            selectionMode: "multiple",
            isCompact: true,
            children: [...stackFrame(initiator.stack)] as any,
          })}
        </div>
      </div>
    )
  }

  return null
}
