import { css } from "@emotion/css"

export const triggerStyle = css`
  background: light-dark(rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.7));
  border: 0.5px solid light-dark(#cdc7c2, #1c1c1c);
  box-shadow: none;
  transition: 0.2s;

  &:hover,
  &:focus {
    /* background-color: light-dark(#f6f5f4, #353535); */
    background-color: light-dark(#fff, #353535);
    border-bottom-color: light-dark(#bfb8b1, #070707);
    box-shadow:
      inset 0 1px light-dark(white, rgba(255, 255, 255, 0.02)),
      0 1px 2px rgba(from var(--shadow-color) r g b / 0.03);

    &::before {
      content: " ";
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.03), transparent);
      transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    &::after {
      content: "";
      position: absolute;
      pointer-events: none;
      z-index: 2;
      display: inline-block;
      border: 2px solid transparent;
      border-radius: inherit;
      transition: var(--focus-transition);
      top: -4px;
      left: -4px;
      width: calc(100% - -4px);
      height: calc(100% - -4px);
    }
  }
`
