export function getPropertyValue(
  object: Record<string, any>,
  propertyName: PropertyKey
): unknown {
  const propertyDescriptor = Object.getOwnPropertyDescriptor(object, propertyName)
  if (propertyDescriptor?.get) {
    try {
      return propertyDescriptor.get()
    } catch {
      return propertyDescriptor.get
    }
  }

  return object[propertyName as keyof typeof object]
}

export function asKey(propertyKey: PropertyKey) {
  return typeof propertyKey === "symbol"
    ? `Symbol(${propertyKey.description})`
    : propertyKey
}
