import { createStore, del, get, set } from "idb-keyval"

const HANDLE = "fileHandle"

const store = createStore("keyval", "keyval-store")

export async function getSavedFileHandle() {
  return await get<FileSystemFileHandle>(HANDLE, store)
}

export async function clearHandle() {
  return await del(HANDLE, store)
}

export async function openFileWithHandle() {
  if ("showOpenFilePicker" in window) {
    const [picker] = await showOpenFilePicker({
      multiple: false,
      types: [
        {
          description: "HAR files",
          accept: {
            "application/json": [".har", ".json"],
          },
        },
      ],
    })
    await picker.requestPermission({ mode: "read" })
    await set(HANDLE, picker, store)
    return picker.getFile()
  }

  return openFile()
}

export async function openFile() {
  const input = document.createElement("input")
  input.type = "file"
  input.accept = ".har,.json"

  return new Promise<File>((resolve, reject) => {
    input.onchange = () => {
      const file = input.files?.[0]
      if (!file) return
      resolve(file)
    }
    input.onerror = reject
    input.click()
  })
}
