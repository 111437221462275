import { createContext, useContext } from "react"

export interface InspectorPropsContext {
  selected?: unknown
  setSelected?: (value: unknown) => void
}

export interface InspectorContext extends InspectorPropsContext {}

export const InspectorContext = createContext<InspectorContext>(undefined!)

export const useInspectorContext = () => useContext(InspectorContext)
