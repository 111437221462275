/**
 * mime-types
 * Copyright(c) 2014 Jonathan Ong
 * Copyright(c) 2015 Douglas Christopher Wilson
 * @license MIT
 */

import { extname } from "node:path"

import db from "mime-db"

// Score RFC facets (see https://tools.ietf.org/html/rfc6838#section-3)
const FACET_SCORES: Record<string, number> = {
  "prs.": 100,
  "x-": 200,
  "x.": 300,
  "vnd.": 400,
  default: 900,
}

// Score mime source (Logic originally from `jshttp/mime-types` module)
const SOURCE_SCORES: Record<string, number> = {
  nginx: 10,
  apache: 20,
  iana: 40,
  default: 30, // definitions added by `jshttp/mime-db` project?
}

const TYPE_SCORES: Record<string, number> = {
  // prefer application/xml over text/xml
  // prefer application/rtf over text/rtf
  application: 1,

  // prefer font/woff over application/font-woff
  font: 2,

  default: 0,
}

/**
 * Get each component of the score for a mime type.  The sum of these is the
 * total score.  The higher the score, the more "official" the type.
 */
function mimeScore(mimeType: string, source = "default") {
  if (mimeType === "application/octet-stream") {
    return 0
  }

  const [type, subtype] = mimeType.split("/")

  const facet = subtype.replace(/(\.|x-).*/, "$1")

  const facetScore = FACET_SCORES[facet] || FACET_SCORES.default
  const sourceScore = SOURCE_SCORES[source] || SOURCE_SCORES.default
  const typeScore = TYPE_SCORES[type] || TYPE_SCORES.default

  // All else being equal prefer shorter types
  const lengthScore = 1 - mimeType.length / 100

  return facetScore + sourceScore + typeScore + lengthScore
}

/**
 * Module variables.
 * @private
 */

const EXTRACT_TYPE_REGEXP = /^\s*([^\s;]*)(?:;|\s|$)/
const TEXT_TYPE_REGEXP = /^text\//i

export const charsets = { lookup: charset }
export const extensions: Record<string, readonly string[]> = Object.create(null)
export const types: Record<string, string> = Object.create(null)
export const _extensionConflicts = []

// Populate the extensions/types maps
populateMaps()

/**
 * Get the default charset for a MIME type.
 */
export function charset(type: string): false | string {
  if (!type || typeof type !== "string") {
    return false
  }

  // TODO: use media-typer
  const match = EXTRACT_TYPE_REGEXP.exec(type)
  const mime = match && db[match[1].toLowerCase()]

  if (mime?.charset) {
    return mime.charset
  }

  // default text/* to utf-8
  if (match && TEXT_TYPE_REGEXP.test(match[1])) {
    return "UTF-8"
  }

  return false
}

/**
 * Create a full Content-Type header given a MIME type or extension.
 *
 * When given an extension, `lookup` is used to get the matching content-type,
 * otherwise the given content-type is used. Then if the content-type does not
 * already have a `charset` parameter, `charset` is used to get the default
 * charset and add to the returned content-type.
 */
export function contentType(str: string): false | string {
  // TODO: should this even be in this module?
  if (!str || typeof str !== "string") {
    return false
  }

  let mime = !str.includes("/") ? lookup(str) : str

  if (!mime) {
    return false
  }

  // TODO: use content-type or other module
  if (!mime.includes("charset")) {
    const charSet = charset(mime)
    if (charSet) mime += "; charset=" + charSet.toLowerCase()
  }

  return mime
}

/**
 * Get the default extension for a MIME type.
 */
export function extension(type: string): boolean | string {
  if (!type || typeof type !== "string") {
    return false
  }

  // TODO: use media-typer
  const match = EXTRACT_TYPE_REGEXP.exec(type)

  // get extensions
  const exts = match && extensions[match[1].toLowerCase()]

  if (!exts || !exts.length) {
    return false
  }

  return exts[0]
}

/**
 * Lookup the MIME type for a file path/extension.
 */
export function lookup(path: string): false | string {
  if (!path || typeof path !== "string") {
    return false
  }

  // get the extension ("ext" or ".ext" or full path)
  const extension = extname("x." + path)
    .toLowerCase()
    .slice(1)

  if (!extension) {
    return false
  }

  return types[extension] || false
}

/**
 * Populate the extensions and types maps.
 */
function populateMaps() {
  for (const [type, mime] of Object.entries(db)) {
    const exts = mime.extensions

    if (!exts || !exts.length) {
      continue
    }

    // mime -> extensions
    extensions[type] = exts

    // extension -> mime
    for (const extension of exts) {
      types[extension] = _preferredType(types[extension], type)
    }
  }
}

// Resolve type conflict using mime-score
function _preferredType(type0: string, type1: string) {
  const score0 = type0 ? mimeScore(type0, db[type0].source) : 0
  const score1 = type1 ? mimeScore(type1, db[type1].source) : 0

  return score0 > score1 ? type0 : type1
}
