import { KVTable } from "~/components/KVTable"
import _ from "~/tokens"

import type { Entry } from "../har"

function ms(name: string, value: number) {
  if (value === -1 || value == null) return

  return { name, value: value.toFixed(2) + " ms" }
}

export function TimelineTab({ entry }: { entry?: Entry }) {
  if (!entry) {
    return null
  }

  return (
    <div className="m-3">
      <KVTable
        title="Timings"
        values={[
          {
            name: "Start",
            value: entry.startedDateTime,
          },
          ms("Blocked", entry.timings.blocked),
          ms("DNS", entry.timings.dns),
          ms("Connect", entry.timings.connect),
          ms("Send", entry.timings.send),
          ms("Wait", entry.timings.wait),
          ms("Receive", entry.timings.receive),
          ms("SSL", entry.timings.ssl),
          ms("Total", entry.time),
        ].filter(Boolean)}
      />
    </div>
  )
}
