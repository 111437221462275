export function count<T, K = string>(
  list: readonly T[] | undefined,
  getKey: (item: T) => K
) {
  const counts = new Map<K, number>()
  list?.forEach(entry => {
    const key = getKey(entry)
    counts.set(key, (counts.get(key) ?? 0) + 1)
  })
  return counts
}
