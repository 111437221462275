import { monaco, useMonacoTheme } from "~/monaco"
import { Editor } from "~/monaco/Monaco"

export function RawEditor({
  readOnly,
  options,
  ...rest
}: Omit<React.ComponentProps<typeof Editor>, "monaco"> & {
  readOnly?: boolean
}) {
  const theme = useMonacoTheme()

  return (
    <Editor
      {...rest}
      monaco={monaco}
      options={{
        fontFamily: "var(--font-mono)",
        fontSize: 14,
        wordWrap: "on",
        minimap: { enabled: false },
        theme,
        readOnly,
        scrollBeyondLastLine: false,
        tabSize: 2,
        ...options,
      }}
    />
  )
}
