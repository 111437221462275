import { css } from "@emotion/css"
import { ErrorBoundary, type FallbackProps } from "react-error-boundary"

const ErrorView = ({ error }: FallbackProps) => (
  <div
    className={css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;
      --monospace: "IBM Plex Mono", "SFMono-Regular", Consolas, "Liberation Mono", Menlo,
        Courier, monospace;
      --red: #ff5555;
      --yellow: #e2aa53;
      --purple: #cfa4ff;
      --cyan: #2dd9da;
      --dim: #c9c9c9;
      --window-background: #181818;
      --window-color: #d8d8d8;
      pre {
        font-family: var(--monospace);
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 1em;
        overflow-x: scroll;
        scrollbar-width: none;
      }
      pre::-webkit-scrollbar {
        display: none;
      }
      .file-link {
        text-decoration: underline;
        cursor: pointer;
      }
    `}
  >
    <div
      className={css`
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        margin: 0;
        background: rgba(0, 0, 0, 0.66);
      `}
    >
      <div
        className={css`
          font-family: var(--monospace);
          line-height: 1.5;
          max-width: 80vw;
          color: var(--window-color);
          box-sizing: border-box;
          margin: 30px auto;
          padding: 2.5vh 4vw;
          position: relative;
          background: var(--window-background);
          border-radius: 6px 6px 8px 8px;
          box-shadow:
            0 19px 38px rgba(0, 0, 0, 0.3),
            0 15px 12px rgba(0, 0, 0, 0.22);
          overflow: hidden;
          border-top: 8px solid var(--red);
          direction: ltr;
          text-align: left;
        `}
      >
        <pre
          className={css`
            line-height: 1.3;
            font-weight: 600;
            white-space: pre-wrap;
          `}
        >
          <span
            className={css`
              color: var(--purple);
            `}
          >
            {((window.error = error), error.name)}:{" "}
          </span>
          <span
            className={css`
              color: var(--red);
            `}
          >
            {error.message}
          </span>
        </pre>
        {/* <pre
          className={css`
            color: var(--cyan);
            margin-bottom: 0;
            white-space: pre-wrap;
            word-break: break-all;
          `}
        >
          <a className="file-link">
            /Users/aet/Documents/Git/hargow/src/components/EntryList.tsx:36:26
          </a>
        </pre> */}
        <pre
          className={css`
            color: var(--yellow);
            scrollbar-width: thin;
            &::-webkit-scrollbar {
              display: block;
              height: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background: #999;
              border-radius: 5px;
            }
          `}
        >{`Snippet`}</pre>
        <pre
          className={css`
            font-size: 13px;
            color: var(--dim);
          `}
        >
          {error.stack.split("\n").slice(1).join("\n")}
        </pre>
        <div
          className={css`
            font-size: 13px;
            color: #999;
            border-top: 1px dotted #999;
            padding-top: 13px;
            line-height: 1.8;
          `}
        >
          Click outside, press{" "}
          <kbd
            className={css`
              line-height: 1.5;
              font-family: ui-monospace, Menlo, Monaco, Consolas, "Liberation Mono",
                "Courier New", monospace;
              font-size: 0.75rem;
              font-weight: 700;
              background-color: rgb(38, 40, 44);
              color: rgb(166, 167, 171);
              padding: 0.15rem 0.3rem;
              border-radius: 0.25rem;
              border-width: 0.0625rem 0.0625rem 0.1875rem;
              border-style: solid;
              border-color: rgb(54, 57, 64);
              border-image: initial;
            `}
          >
            Esc
          </kbd>{" "}
          key, or fix the code to dismiss.
          <br />
          You can also disable this overlay by setting{" "}
          <code className="config-option-name">server.hmr.overlay</code> to{" "}
          <code className="config-option-value">false</code> in{" "}
          <code className="config-file-name">vite.config.mts</code>.
        </div>
      </div>
    </div>
  </div>
)

export function Boundary({ children }: { children: React.ReactNode }) {
  return <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>
}
