// https://github.com/tc39/proposal-arraybuffer-base64/tree/4c79da434bd39988eaaacf6fa3ce714c7d9a57c0/playground
declare global {
  interface Uint8Array {
    toBase64(options?: ToBase64Options): string
    setFromBase64(
      string: string,
      options?: FromBase64Options
    ): { read: number; written: number }
    toHex(): string
    setFromHex(string: string): { read: number; written: number }
  }
  interface Uint8ArrayConstructor {
    fromHex(string: string): Uint8Array
    fromBase64(string: string, options?: FromBase64Options): Uint8Array
  }
}

const base64Characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"
const base64UrlCharacters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_"

const tag = Object.getOwnPropertyDescriptor(
  Object.getPrototypeOf(Uint8Array.prototype),
  Symbol.toStringTag
)!.get as (this: unknown) => string

export function checkUint8Array(arg: Uint8Array) {
  let kind
  try {
    kind = tag.call(arg)
  } catch {
    throw new TypeError("not a Uint8Array")
  }
  if (kind !== "Uint8Array") {
    throw new TypeError("not a Uint8Array")
  }
}

function assert(condition: boolean, message?: string): asserts condition {
  if (!condition) {
    throw new Error(`Assert failed: ${message}`)
  }
}

function getOptions<T extends object>(options?: T): T {
  if (options === undefined) {
    return Object.create(null)
  }
  if (options && typeof options === "object") {
    return options
  }
  throw new TypeError("options is not object")
}

export function uint8ArrayToBase64(arr: Uint8Array, options: ToBase64Options) {
  checkUint8Array(arr)
  const opts = getOptions(options)
  let alphabet = opts.alphabet
  if (alphabet === undefined) {
    alphabet = "base64"
  }
  if (alphabet !== "base64" && alphabet !== "base64url") {
    throw new TypeError('expected alphabet to be either "base64" or "base64url"')
  }
  const omitPadding = !!opts.omitPadding

  if ("detached" in arr.buffer && arr.buffer.detached) {
    throw new TypeError("toBase64 called on array backed by detached buffer")
  }

  const lookup = alphabet === "base64" ? base64Characters : base64UrlCharacters
  let result = ""

  let i = 0
  for (; i + 2 < arr.length; i += 3) {
    const triplet = (arr[i] << 16) + (arr[i + 1] << 8) + arr[i + 2]
    result +=
      lookup[(triplet >> 18) & 63] +
      lookup[(triplet >> 12) & 63] +
      lookup[(triplet >> 6) & 63] +
      lookup[triplet & 63]
  }
  if (i + 2 === arr.length) {
    const triplet = (arr[i] << 16) + (arr[i + 1] << 8)
    result +=
      lookup[(triplet >> 18) & 63] +
      lookup[(triplet >> 12) & 63] +
      lookup[(triplet >> 6) & 63] +
      (omitPadding ? "" : "=")
  } else if (i + 1 === arr.length) {
    const triplet = arr[i] << 16
    result +=
      lookup[(triplet >> 18) & 63] +
      lookup[(triplet >> 12) & 63] +
      (omitPadding ? "" : "==")
  }
  return result
}

function decodeBase64Chunk(chunk: string, throwOnExtraBits: boolean) {
  const actualChunkLength = chunk.length
  if (actualChunkLength < 4) {
    chunk += actualChunkLength === 2 ? "AA" : "A"
  }

  const map = new Map(base64Characters.split("").map((c, i) => [c, i]))

  const c1 = chunk[0]
  const c2 = chunk[1]
  const c3 = chunk[2]
  const c4 = chunk[3]

  const triplet =
    (map.get(c1)! << 18) + (map.get(c2)! << 12) + (map.get(c3)! << 6) + map.get(c4)!

  const chunkBytes = [(triplet >> 16) & 255, (triplet >> 8) & 255, triplet & 255]

  if (actualChunkLength === 2) {
    if (throwOnExtraBits && chunkBytes[1] !== 0) {
      throw new SyntaxError("extra bits")
    }
    return [chunkBytes[0]]
  } else if (actualChunkLength === 3) {
    if (throwOnExtraBits && chunkBytes[2] !== 0) {
      throw new SyntaxError("extra bits")
    }
    return [chunkBytes[0], chunkBytes[1]]
  }
  return chunkBytes
}

function skipAsciiWhitespace(string: string, index: number) {
  for (; index < string.length; ++index) {
    // eslint-disable-next-line no-control-regex
    if (!/[\u0009\u000A\u000C\u000D ]/.test(string[index])) {
      break
    }
  }
  return index
}

function fromBase64(
  string: string,
  alphabet: string,
  lastChunkHandling: string,
  maxLength: number
): {
  bytes: number[]
  read: number
  error: SyntaxError | null
} {
  if (maxLength === 0) {
    return { read: 0, bytes: [], error: null }
  }

  let read = 0
  const bytes = []
  let chunk = ""

  let index = 0
  while (true) {
    index = skipAsciiWhitespace(string, index)
    if (index === string.length) {
      if (chunk.length > 0) {
        if (lastChunkHandling === "stop-before-partial") {
          return { bytes, read, error: null }
        } else if (lastChunkHandling === "loose") {
          if (chunk.length === 1) {
            const error = new SyntaxError(
              "malformed padding: exactly one additional character"
            )
            return { bytes, read, error }
          }
          bytes.push(...decodeBase64Chunk(chunk, false))
        } else {
          assert(lastChunkHandling === "strict")
          const error = new SyntaxError("missing padding")
          return { bytes, read, error }
        }
      }
      return { bytes, read: string.length, error: null }
    }
    let char = string[index]
    ++index
    if (char === "=") {
      if (chunk.length < 2) {
        const error = new SyntaxError("padding is too early")
        return { bytes, read, error }
      }
      index = skipAsciiWhitespace(string, index)
      if (chunk.length === 2) {
        if (index === string.length) {
          if (lastChunkHandling === "stop-before-partial") {
            // two characters then `=` then EOS: this is, technically, a partial chunk
            return { bytes, read, error: null }
          }
          const error = new SyntaxError("malformed padding - only one =")
          return { bytes, read, error }
        }
        if (string[index] === "=") {
          ++index
          index = skipAsciiWhitespace(string, index)
        }
      }
      if (index < string.length) {
        const error = new SyntaxError("unexpected character after padding")
        return { bytes, read, error }
      }
      bytes.push(...decodeBase64Chunk(chunk, lastChunkHandling === "strict"))
      assert(bytes.length <= maxLength)
      return { bytes, read: string.length, error: null }
    }
    if (alphabet === "base64url") {
      switch (char) {
        case "+":
        case "/":
          const error = new SyntaxError(`unexpected character ${JSON.stringify(char)}`)
          return { bytes, read, error }

        case "-":
          char = "+"
          break

        case "_":
          char = "/"
          break
      }
    }
    if (!base64Characters.includes(char)) {
      const error = new SyntaxError(`unexpected character ${JSON.stringify(char)}`)
      return { bytes, read, error }
    }
    const remainingBytes = maxLength - bytes.length
    if (
      (remainingBytes === 1 && chunk.length === 2) ||
      (remainingBytes === 2 && chunk.length === 3)
    ) {
      // special case: we can fit exactly the number of bytes currently represented by chunk, so we were just checking for `=`
      return { bytes, read, error: null }
    }

    chunk += char
    if (chunk.length === 4) {
      bytes.push(...decodeBase64Chunk(chunk, false))
      chunk = ""
      read = index
      assert(bytes.length <= maxLength)
      if (bytes.length === maxLength) {
        return { bytes, read, error: null }
      }
    }
  }
}

export function base64ToUint8Array(
  string: string,
  options?: FromBase64Options,
  into?: Uint8Array
) {
  const opts = getOptions(options)
  let alphabet = opts.alphabet
  if (alphabet === undefined) {
    alphabet = "base64"
  }
  if (alphabet !== "base64" && alphabet !== "base64url") {
    throw new TypeError('expected alphabet to be either "base64" or "base64url"')
  }
  let lastChunkHandling = opts.lastChunkHandling
  if (lastChunkHandling === undefined) {
    lastChunkHandling = "loose"
  }
  if (!["loose", "strict", "stop-before-partial"].includes(lastChunkHandling)) {
    throw new TypeError(
      'expected lastChunkHandling to be either "loose", "strict", or "stop-before-partial"'
    )
  }
  if (into && "detached" in into.buffer && into.buffer.detached) {
    throw new TypeError("toBase64Into called on array backed by detached buffer")
  }

  const maxLength = into ? into.length : 2 ** 53 - 1

  const { bytes, read, error } = fromBase64(
    string,
    alphabet,
    lastChunkHandling,
    maxLength
  )
  if (error && !into) {
    throw error
  }

  const array = new Uint8Array(bytes)
  if (into && array.length > 0) {
    assert(array.length <= into.length)
    into.set(array)
  }

  if (error) {
    throw error
  }

  return { read, bytes: array }
}

export function uint8ArrayToHex(arr: Uint8Array) {
  checkUint8Array(arr)
  if ("detached" in arr.buffer && arr.buffer.detached) {
    throw new TypeError("toHex called on array backed by detached buffer")
  }
  let out = ""
  for (const element of arr) {
    out += element.toString(16).padStart(2, "0")
  }
  return out
}

function fromHex(string: string, maxLength: number) {
  const bytes: number[] = []
  let read = 0
  if (maxLength > 0) {
    while (read < string.length) {
      const hexits = string.slice(read, read + 2)
      if (/[^\dA-Fa-f]/.test(hexits)) {
        const error = new SyntaxError("string should only contain hex characters")
        return { read, bytes, error }
      }
      bytes.push(parseInt(hexits, 16))
      read += 2
      if (bytes.length === maxLength) {
        break
      }
    }
  }
  return { read, bytes, error: null }
}

export function hexToUint8Array(string: string, into?: Uint8Array) {
  if (typeof string !== "string") {
    throw new TypeError("expected string to be a string")
  }
  if (into && "detached" in into.buffer && into.buffer.detached) {
    throw new TypeError("fromHexInto called on array backed by detached buffer")
  }
  if (string.length % 2 !== 0) {
    throw new SyntaxError("string should be an even number of characters")
  }

  const maxLength = into ? into.length : 2 ** 53 - 1
  const { read, bytes, error } = fromHex(string, maxLength)
  if (error && !into) {
    throw error
  }

  const array = new Uint8Array(bytes)
  if (into && array.length > 0) {
    assert(array.length <= into.length)
    into.set(array)
  }

  if (error) {
    throw error
  }

  return { read, bytes: array }
}

interface ToBase64Options {
  alphabet?: "base64" | "base64url"
  omitPadding?: boolean
}

interface FromBase64Options {
  alphabet?: "base64" | "base64url"
  lastChunkHandling?: "loose" | "strict" | "stop-before-partial"
}

if (!Uint8Array.prototype.toBase64) {
  Uint8Array.prototype.toBase64 = {
    toBase64(this: Uint8Array, options: ToBase64Options) {
      return uint8ArrayToBase64(this, options)
    },
  }.toBase64
  Object.defineProperty(Uint8Array.prototype, "toBase64", { enumerable: false })
  Object.defineProperty(Uint8Array.prototype.toBase64, "length", { value: 0 })
}

if (!Uint8Array.prototype.setFromBase64) {
  Uint8Array.fromBase64 = (string, options) => {
    if (typeof string !== "string") {
      throw new TypeError("expected input to be a string")
    }
    return base64ToUint8Array(string, options).bytes
  }
  Object.defineProperty(Uint8Array, "fromBase64", { enumerable: false })
  Object.defineProperty(Uint8Array.fromBase64, "length", { value: 1 })
  Object.defineProperty(Uint8Array.fromBase64, "name", { value: "fromBase64" })
}

// method shenanigans to make a non-constructor which can refer to "this"
if (!Uint8Array.prototype.setFromBase64) {
  Uint8Array.prototype.setFromBase64 = {
    setFromBase64(this: Uint8Array, string: string, options: FromBase64Options) {
      checkUint8Array(this)
      if (typeof string !== "string") {
        throw new TypeError("expected input to be a string")
      }
      const { read, bytes } = base64ToUint8Array(string, options, this)
      return { read, written: bytes.length }
    },
  }.setFromBase64
  Object.defineProperty(Uint8Array.prototype, "setFromBase64", { enumerable: false })
  Object.defineProperty(Uint8Array.prototype.setFromBase64, "length", { value: 1 })
}

if (!Uint8Array.prototype.toHex) {
  Uint8Array.prototype.toHex = {
    toHex(this: Uint8Array) {
      return uint8ArrayToHex(this)
    },
  }.toHex
  Object.defineProperty(Uint8Array.prototype, "toHex", { enumerable: false })
}

if (!Uint8Array.fromHex) {
  Uint8Array.fromHex = string => {
    if (typeof string !== "string") {
      throw new TypeError("expected input to be a string")
    }
    return hexToUint8Array(string).bytes
  }
  Object.defineProperty(Uint8Array, "fromHex", { enumerable: false })
  Object.defineProperty(Uint8Array.fromHex, "name", { value: "fromHex" })
}

if (!Uint8Array.prototype.setFromHex) {
  Uint8Array.prototype.setFromHex = {
    setFromHex(this: Uint8Array, string: string) {
      checkUint8Array(this)
      if (typeof string !== "string") {
        throw new TypeError("expected input to be a string")
      }
      const { read, bytes } = hexToUint8Array(string, this)
      return { read, written: bytes.length }
    },
  }.setFromHex
  Object.defineProperty(Uint8Array.prototype, "setFromHex", { enumerable: false })
}

export {}
