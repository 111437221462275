import { StrictMode } from "react"
import { createRoot } from "react-dom/client"

import "@fontsource/ibm-plex-mono"
import "./index.css"
import { NextUIProvider } from "nextui"
import { ThemeProvider as NextThemesProvider } from "nextui/theme"

export function renderMain(children: React.ReactNode) {
  createRoot(document.getElementById("root")!).render(
    <StrictMode>
      <NextUIProvider>
        <NextThemesProvider attribute="class">{children}</NextThemesProvider>
      </NextUIProvider>
    </StrictMode>
  )
}
