export function* domIterator(data: Node) {
  if (data?.childNodes) {
    const textInlined = shouldInline(data)
    if (textInlined) return

    for (let i = 0; i < data.childNodes.length; i++) {
      const node = data.childNodes[i]

      if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim().length === 0) {
        continue
      }

      yield {
        name: `${(node as Element).tagName}[${i}]`,
        data: node,
      }
    }

    // at least 1 child node
    if ((data as Element).tagName) {
      yield {
        name: "CLOSE_TAG",
        data: {
          tagName: (data as Element).tagName,
        },
        isCloseTag: true,
      }
    }
  }
}

const TEXT_NODE_MAX_INLINE_CHARS = 80

export const shouldInline = (data: Node) =>
  data.childNodes.length === 0 ||
  (data.childNodes.length === 1 &&
    data.childNodes[0].nodeType === Node.TEXT_NODE &&
    (data as Text).textContent!.length < TEXT_NODE_MAX_INLINE_CHARS)
