import { useMemo } from "react"

import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "nextui"

export function KVTable({
  title,
  className,
  emptyContent,
  values,
  sort,
}: {
  title: string | React.ReactElement
  className?: string
  emptyContent?: React.ReactNode
  values: {
    name: string
    value?: React.ReactNode
  }[]
  sort?: boolean
}) {
  const sorted = useMemo(
    () => (sort ? values.sort((a, b) => a.name.localeCompare(b.name)) : values),
    [values, sort]
  )

  return (
    <div className={className}>
      {typeof title === "string" ? (
        <h3 css="mb-2 ml-1 text-sm font-medium uppercase tracking-widest opacity-50">
          {title}
        </h3>
      ) : (
        title
      )}
      <Table hideHeader isStriped aria-label="Query string">
        <TableHeader>
          <TableColumn>Title</TableColumn>
          <TableColumn>Value</TableColumn>
        </TableHeader>
        <TableBody emptyContent={emptyContent}>
          {sorted.map(entry => (
            <TableRow
              key={entry.name + (typeof entry.value === "string" ? entry.value : "")}
            >
              <TableCell className="what" css="w-48 max-w-48 align-top font-medium">
                {entry.name}
              </TableCell>
              <TableCell css="max-w-64 text-wrap break-words" height={35}>
                {entry.value === "" || entry.value == null ? (
                  <span css="italic opacity-40">Empty</span>
                ) : (
                  entry.value
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
