import { css, cx } from "@emotion/css"
import JSON5 from "json5"
import get from "lodash-es/get"
import { memo, useMemo, useState } from "react"

import { BreadcrumbItem, Breadcrumbs, Input } from "nextui"
import { Inspector } from "~/react-inspector"

export const JSONView = memo(
  ({
    name,
    data,
    className,
    minimal,
  }: {
    name?: string
    data: any
    className?: string
    minimal?: boolean
  }) => {
    const [search, setSearch] = useState("")
    const parsed = useMemo(() => JSON5.parse(data), [data])

    const result = useMemo(() => {
      if (!search) return parsed

      try {
        const result = get(parsed, search.replace(/^\./, ""))
        if (result !== undefined) {
          return result
        }
      } catch {}
      return parsed
    }, [parsed, search])

    return (
      <div className="ml-2 grid grid-rows-[auto_1fr]">
        {!minimal && false && (
          <Breadcrumbs className="mb-2 ml-1">
            <BreadcrumbItem>Home</BreadcrumbItem>
            <BreadcrumbItem>Document</BreadcrumbItem>
          </Breadcrumbs>
        )}
        <div className={cx("font-mono min-h-24", className)}>
          <Inspector
            sortObjectKeys
            data={result}
            expandLevel={3}
            expandPaths={[]}
            name={search || name}
            showNonEnumerable={false}
            table={false}
          />
        </div>
        {!minimal && (
          <div>
            <Input
              className="w-full"
              placeholder="Evaluate"
              value={search}
              variant="bordered"
              classNames={{
                inputWrapper: css`
                  border-width: 1px;
                `,
              }}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
        )}
      </div>
    )
  }
)
