import { KVTable } from "~/components/KVTable"
import { Button, Chip } from "~/nextui"

import { InitiatorView } from "../components/Initiator"
import type { Entry, Request } from "../har"

import { PostData } from "./PostData"

export function RequestTab({ entry }: { entry?: Entry }) {
  if (!entry) {
    return null
  }

  const { request, _initiator } = entry

  return (
    <div css="m-3 mb-6">
      <div css="m-1 mb-4 flex items-center gap-2">
        <Chip>{request.method}</Chip>{" "}
        <div css="text-wrap break-all text-base">{request.url}</div>
      </div>
      {_initiator && <InitiatorView initiator={_initiator} />}
      <PostData postData={request.postData} />
      {request.queryString.length > 0 && (
        <KVTable sort css="mb-6" title="Query string" values={request.queryString} />
      )}
      <KVTable sort emptyContent="No headers" title="Headers" values={request.headers} />

      <div css="my-4">
        <Button isDisabled title="Not implemented">
          Copy as fetch
        </Button>
      </div>
    </div>
  )
}

// https://github.com/ChromeDevTools/devtools-frontend/blob/a8d9f99d7b79b372e6338069b8978c05c04b2c7a/front_end/panels/network/NetworkLogView.ts
const enum FetchStyle {
  BROWSER = 0,
  NODE_JS = 1,
}

async function generateFetchCall(request: Request, style: FetchStyle): Promise<string> {
  const ignoredHeaders = new Set<string>([
    // Internal headers
    "method",
    "path",
    "scheme",
    "version",

    // Unsafe headers
    // Keep this list synchronized with src/net/http/http_util.cc
    "accept-charset",
    "accept-encoding",
    "access-control-request-headers",
    "access-control-request-method",
    "connection",
    "content-length",
    "cookie",
    "cookie2",
    "date",
    "dnt",
    "expect",
    "host",
    "keep-alive",
    "origin",
    "referer",
    "te",
    "trailer",
    "transfer-encoding",
    "upgrade",
    "via",
    // TODO(phistuck) - remove this once crbug.com/571722 is fixed.
    "user-agent",
  ])

  const credentialHeaders = new Set<string>(["cookie", "authorization"])

  const url = JSON.stringify(request.url)

  const requestHeaders = request.headers
  const headerData: Headers = requestHeaders.reduce((result, header) => {
    const name = header.name

    if (!ignoredHeaders.has(name.toLowerCase()) && !name.includes(":")) {
      result.append(name, header.value)
    }

    return result
  }, new Headers())

  const headers: HeadersInit = {}
  for (const headerArray of headerData) {
    headers[headerArray[0]] = headerArray[1]
  }

  const credentials =
    request.includedRequestCookies().length ||
    requestHeaders.some(({ name }) => credentialHeaders.has(name.toLowerCase()))
      ? "include"
      : "omit"

  const referrerHeader = requestHeaders.find(
    ({ name }) => name.toLowerCase() === "referer"
  )

  const referrer = referrerHeader ? referrerHeader.value : void 0

  // #referrerPolicyInternal
  const referrerPolicy = request.referrerPolicy() || void 0

  const requestBody = await request.requestFormData()

  const fetchOptions: RequestInit = {
    headers: Object.keys(headers).length ? headers : void 0,
    referrer,
    referrerPolicy,
    body: requestBody,
    method: request.method,
    mode: "cors",
  }

  if (style === FetchStyle.NODE_JS) {
    const cookieHeader = requestHeaders.find(
      header => header.name.toLowerCase() === "cookie"
    )
    const extraHeaders: HeadersInit = {}
    // According to https://www.npmjs.com/package/node-fetch#class-request the
    // following properties are not implemented in Node.js.
    delete fetchOptions.mode
    if (cookieHeader) {
      extraHeaders["cookie"] = cookieHeader.value
    }
    if (referrer) {
      delete fetchOptions.referrer
      extraHeaders["Referer"] = referrer
    }
    if (referrer) {
      delete fetchOptions.referrerPolicy
      extraHeaders["Referrer-Policy"] = referrerPolicy as string
    }
    if (Object.keys(extraHeaders).length) {
      fetchOptions.headers = {
        ...headers,
        ...extraHeaders,
      }
    }
  } else {
    fetchOptions.credentials = credentials
  }

  const options = JSON.stringify(fetchOptions, null, 2)
  return `fetch(${url}, ${options});`
}
