import type { Chip } from "nextui"

export function getStatusColor(
  status: number
): React.ComponentProps<typeof Chip>["color"] {
  switch (Math.trunc(status / 100)) {
    case 2:
      return "success"
    case 3:
      return "warning"
    case 4:
    case 5:
      return "danger"
    default:
      return "default"
  }
}

export function getMethodColor(method: string) {
  switch (method) {
    case "POST":
      return "primary"
    case "DELETE":
      return "danger"
    default:
      return "default"
  }
}
