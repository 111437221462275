import { css, cx } from "@emotion/css"
import { memo } from "react"

import { DOMInspector, HTML_ATTRIBUTE_NAME_COLOR, HTML_TAGNAME_COLOR } from "./DOM"
import { createObjectIterator } from "./iterators/Object"
import { lightDark } from "./tokens"
import { type NodeRenderer, TreeView } from "./TreeView"
import { getPropertyValue } from "./utils"

const OBJECT_NAME_COLOR = lightDark("#881391", "#e36eec")
const OBJECT_VALUE_NULL_COLOR = lightDark("#808080", "#7f7f7f")
const OBJECT_VALUE_UNDEFINED_COLOR = lightDark("#808080", "#7f7f7f")
const OBJECT_VALUE_REGEXP_COLOR = lightDark("#c41a16", "#e93f3b")
const OBJECT_VALUE_STRING_COLOR = lightDark("#c41a16", "#e93f3b")
const OBJECT_VALUE_SYMBOL_COLOR = lightDark("#c41a16", "#e93f3b")
const OBJECT_VALUE_NUMBER_COLOR = lightDark("#1c00cf", "#9980ff")
const OBJECT_VALUE_BOOLEAN_COLOR = lightDark("#1c00cf", "#9980ff")
const OBJECT_VALUE_FUNCTION_PREFIX_COLOR = lightDark("#0d22aa", "#556af2")

/**
 * A view for object property names.
 *
 * If the property name is enumerable (in Object.keys(object)),
 * the property name will be rendered normally.
 *
 * If the property name is not enumerable (`Object.prototype.propertyIsEnumerable()`),
 * the property name will be dimmed to show the difference.
 */
function ObjectName({
  name,
  dimmed = false,
  preview = false,
}: {
  name: string
  dimmed?: boolean
  preview?: boolean
}) {
  return (
    <span
      className={cx(
        css({ color: preview ? "#8f8f8f" : OBJECT_NAME_COLOR }),
        dimmed && css({ opacity: 0.6 })
      )}
    >
      {name}
    </span>
  )
}

/**
 * A short description of the object values.
 * Can be used to render tree node in ObjectInspector
 * or render objects in TableInspector.
 */
export const ObjectValue: React.FC<{
  object: any
  preview?: boolean
}> = ({ object, preview }) => {
  const numberStyle = css({
    color: OBJECT_VALUE_NUMBER_COLOR,
  })

  switch (typeof object) {
    case "bigint":
      return <span className={numberStyle}>{String(object)}n</span>
    case "number":
      return <span className={numberStyle}>{String(object)}</span>
    case "string":
      let text = object
      if (preview && object.length > 100) {
        const left = object.slice(0, 50)
        const right = object.slice(-50)
        text = `${left}…${right}`
      }

      return (
        <span
          className={css`
            color: ${OBJECT_VALUE_STRING_COLOR};
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {'"'}
          {text}
          {'"'}
        </span>
      )
    case "boolean":
      return (
        <span className={css({ color: OBJECT_VALUE_BOOLEAN_COLOR })}>
          {String(object)}
        </span>
      )
    case "undefined":
      return (
        <span className={css({ color: OBJECT_VALUE_UNDEFINED_COLOR })}>undefined</span>
      )
    case "object":
      if (object === null) {
        return <span className={css({ color: OBJECT_VALUE_NULL_COLOR })}>null</span>
      }
      if (object instanceof Date) {
        return <span>{object.toString()}</span>
      }
      if (object instanceof RegExp) {
        return (
          <span className={css({ color: OBJECT_VALUE_REGEXP_COLOR })}>
            {object.toString()}
          </span>
        )
      }
      if (object instanceof Element) {
        if (preview) {
          return (
            <span>
              <span
                className={css({ color: HTML_TAGNAME_COLOR })}
              >{`${object.localName}`}</span>
              {object.className.length > 0 && (
                <span className={css({ color: HTML_ATTRIBUTE_NAME_COLOR })}>
                  .{object.className.replaceAll(" ", ".")}
                </span>
              )}
            </span>
          )
        }

        return <DOMInspector data={object} expandLevel={0} expandPaths={[]} name="" />
      }
      if (Array.isArray(object)) {
        return <span>{`Array(${object.length})`}</span>
      }
      if (!object.constructor) {
        return <span>Object</span>
      }
      if (
        typeof object.constructor.isBuffer === "function" &&
        object.constructor.isBuffer(object)
      ) {
        return <span>{`Buffer[${object.length}]`}</span>
      }

      return <span>{object.constructor.name}</span>
    case "function":
      return (
        <span>
          <span
            className={css({
              color: OBJECT_VALUE_FUNCTION_PREFIX_COLOR,
              fontStyle: "italic",
            })}
          >
            ƒ&nbsp;
          </span>
          <span className="italic">{object.name}()</span>
        </span>
      )
    case "symbol":
      return (
        <span className={css({ color: OBJECT_VALUE_SYMBOL_COLOR })}>
          {object.toString()}
        </span>
      )
    default:
      return <span />
  }
}

interface ObjectLabelProps {
  name: string | React.ReactNode
  data: unknown
  /** Non enumerable object property will be dimmed */
  isNonEnumerable?: boolean
}

/**
 * if isNonEnumerable is specified, render the name dimmed
 */
function ObjectLabel({ name, data, isNonEnumerable = false }: ObjectLabelProps) {
  const object = data

  return (
    <span>
      {typeof name === "string" ? (
        <ObjectName dimmed={isNonEnumerable} name={name} />
      ) : (
        <ObjectPreview data={name} />
      )}
      <span>: </span>
      <ObjectValue object={object} />
    </span>
  )
}

function ObjectRootLabel({ name, data }: { name?: string | React.ReactNode; data: any }) {
  return typeof name === "string" ? (
    <span>
      <ObjectName name={name} />
      <span>: </span>
      <ObjectPreview data={data} />
    </span>
  ) : (
    <ObjectPreview data={data} />
  )
}

const defaultNodeRenderer: NodeRenderer = ({ depth, name, data, isNonEnumerable }) =>
  depth === 0 ? (
    <ObjectRootLabel data={data} name={name} />
  ) : (
    <ObjectLabel data={data} isNonEnumerable={isNonEnumerable} name={name} />
  )

export interface ObjectInspectorProps {
  /** An integer specifying to which level the tree should be initially expanded. */
  expandLevel: number
  /** An array containing all the paths that should be expanded when the component is initialized, or a string of just one path */
  expandPaths: string[]
  name?: string
  /** Not required prop because we also allow undefined value */
  data: unknown
  /** Show non-enumerable properties */
  showNonEnumerable: boolean
  /** Sort object keys with optional compare function. */
  sortObjectKeys: boolean | ((a: string, b: string) => number)
  /** Provide a custom nodeRenderer */
  nodeRenderer?: NodeRenderer
}

/**
 * Tree-view for objects
 */
export function ObjectInspector({
  showNonEnumerable = false,
  sortObjectKeys,
  nodeRenderer,
  ...treeViewProps
}: ObjectInspectorProps) {
  const dataIterator = createObjectIterator(showNonEnumerable, sortObjectKeys)
  const renderer = nodeRenderer ?? defaultNodeRenderer

  return (
    <div className="ml-1">
      <TreeView dataIterator={dataIterator} nodeRenderer={renderer} {...treeViewProps} />
    </div>
  )
}

/* intersperse arr with separator */
function intersperse(arr: React.ReactNode[], sep: string): React.ReactNode[] {
  if (arr.length === 0) {
    return []
  }

  return arr
    .slice(1)
    .reduce(
      (xs, x) => (xs as React.ReactNode[]).concat([sep, x]),
      [arr[0]]
    ) as React.ReactNode[]
}

const styles = {
  objectDescription: css`
    font-style: italic;
  `,
  preview: css`
    font-style: italic;
  `,
  arrayMaxProperties: 10,
  objectMaxProperties: 5,
}

/**
 * A preview of the object
 */
const ObjectPreview = memo(({ data }: { data: unknown }) => {
  const object = data

  if (
    typeof object !== "object" ||
    object === null ||
    object instanceof Date ||
    object instanceof RegExp
  ) {
    return <ObjectValue object={object} />
  }

  if (Array.isArray(object)) {
    const maxProperties = styles.arrayMaxProperties
    const previewArray = object
      .slice(0, maxProperties)
      .map((element, index) => <ObjectValue key={index} object={element} />)
    if (object.length > maxProperties) {
      previewArray.push(<span key="ellipsis">…</span>)
    }
    const arrayLength = object.length
    return (
      <>
        <span className={styles.objectDescription}>
          {arrayLength === 0 ? `` : `(${arrayLength})\xA0`}
        </span>
        <span className={styles.preview}>[{intersperse(previewArray, ", ")}]</span>
      </>
    )
  } else {
    const maxProperties = styles.objectMaxProperties
    const propertyNodes: React.ReactNode[] = []
    for (const propertyName in object) {
      if (Object.hasOwn(object, propertyName)) {
        let ellipsis
        if (
          propertyNodes.length === maxProperties - 1 &&
          Object.keys(object).length > maxProperties
        ) {
          ellipsis = <span key={"ellipsis"}>…</span>
        }

        const propertyValue = getPropertyValue(object, propertyName)
        propertyNodes.push(
          <span key={propertyName}>
            <ObjectName preview name={propertyName || `""`} />
            :&nbsp;
            <ObjectValue preview object={propertyValue} />
            {ellipsis}
          </span>
        )
        if (ellipsis) break
      }
    }

    const objectConstructorName = object.constructor ? object.constructor.name : "Object"

    return (
      <>
        <span className={styles.objectDescription}>
          {objectConstructorName === "Object" ? "" : `${objectConstructorName} `}
        </span>
        <span className={styles.preview}>
          {"{"}
          {intersperse(propertyNodes, ", ")}
          {"}"}
        </span>
      </>
    )
  }
})
