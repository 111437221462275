import { useMemo } from "react"

import { MultipleSelect } from "../../components/Select"
import { count } from "../../toolbar/functions"
import { triggerStyle } from "../../toolbar/style"
import type { Har } from "../har"

export function HostTypeFilter({
  har,
  value,
  onChange,
  placeholder = "Host",
}: {
  har?: Har
  value: string[]
  onChange: (value: React.SetStateAction<string[]>) => void
  placeholder?: string
}) {
  const counts = useMemo(
    () => count(har?.log.entries, e => new URL(e.request.url).host),
    [har]
  )

  return (
    <MultipleSelect
      aria-label="Host"
      placeholder={placeholder}
      size="sm"
      value={value}
      variant="flat"
      classNames={{
        trigger: triggerStyle,
      }}
      options={Array.from(counts)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([key, count]) => ({
          key,
          label: `${key} (${count})`,
        }))}
      onChange={onChange}
    />
  )
}
