import "~/polyfills/Uint8Array_base64"
import { basename, extname } from "node:path"

import { css } from "@emotion/css"
import type { FileTypeResult } from "file-type"
import { Download } from "lucide-react"

import { Button, Chip } from "nextui"
import type { Content } from "~/har/har"

import * as mime from "../../mime"

export function BodyHeader({
  url,
  content,
  fileType,
}: {
  url: string
  content: Content
  fileType: FileTypeResult | undefined
}) {
  const text = content.text!
  const mimeType = content.mimeType
  const fileName = basename(url, extname(url))

  return (
    <div css="mb-2 ml-2 mr-3 mt-1 flex border-b border-solid border-b-gray-100 dark:border-b-gray-800">
      <div className="flex grow gap-2 pb-2">
        <KV
          name="MIME"
          value={fileType ? `${mimeType} (guess: ${fileType.mime})` : mimeType}
        />
        <KV name="Size" value={content.size.toLocaleString() + " bytes"} />
        {content.encoding !== "base64" && <KV name="Encoding" value={content.encoding} />}
      </div>
      <div>
        <Button
          size="sm"
          startContent={<Download size="14" />}
          onPress={() => {
            const name = mimeType
              ? `${fileName}.${mime.extension(mimeType)}`
              : basename(url)
            const blob = new Blob(
              [content.encoding === "base64" ? Uint8Array.fromBase64(text) : text],
              { type: mimeType }
            )
            const href = URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = href
            a.download = name
            a.click()
            URL.revokeObjectURL(href)
          }}
        >
          Download
        </Button>
      </div>
    </div>
  )
}

function KV({ name, value }: { name: string; value?: string | number }) {
  if (value === undefined) {
    return null
  }

  return (
    <div
      className={css`
        &:not(:last-child) {
          border-right: 0.5px solid light-dark(#eee, #333);
          padding-right: 7px;
        }
      `}
    >
      <Chip radius="sm" variant="flat">
        {name}
      </Chip>{" "}
      <span className="text-sm">{value}</span>
    </div>
  )
}
