import * as monaco from "monaco-editor"
import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker"
// eslint-disable-next-line import-x/default
import cssWorker from "monaco-editor/esm/vs/language/css/css.worker?worker"
// eslint-disable-next-line import-x/default
import htmlWorker from "monaco-editor/esm/vs/language/html/html.worker?worker"
// eslint-disable-next-line import-x/default
import jsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker"
import tsWorker from "monaco-editor/esm/vs/language/typescript/ts.worker?worker"

import { useTheme } from "nextui/theme"

import githubDark from "./githubDark.json"
import githubLight from "./githubLight.json"

export * as monaco from "monaco-editor"

self.MonacoEnvironment = {
  getWorker(_: any, label: string) {
    if (label === "json") {
      return new jsonWorker()
    }
    if (label === "css" || label === "scss" || label === "less") {
      return new cssWorker()
    }
    if (label === "html" || label === "handlebars" || label === "razor") {
      return new htmlWorker()
    }
    if (label === "typescript" || label === "javascript") {
      return new tsWorker()
    }
    return new editorWorker()
  },
}

monaco.languages.typescript.typescriptDefaults.setEagerModelSync(true)

monaco.editor.defineTheme(
  "github-light",
  githubLight as monaco.editor.IStandaloneThemeData
)
monaco.editor.defineTheme("github-dark", githubDark as monaco.editor.IStandaloneThemeData)

export function useMonacoTheme() {
  const { resolvedTheme } = useTheme()
  return resolvedTheme === "dark" ? "github-dark" : "github-light"
}
