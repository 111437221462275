import { Chip } from "nextui"
import { KVTable } from "~/components/KVTable"

import { getStatusColor } from "../components/StatusChip"
import type { Entry } from "../har"
import { httpCodes } from "../statusCodes"

export function ResponseTab({ entry }: { entry?: Entry }) {
  if (!entry) {
    return null
  }

  const { response: res } = entry

  return (
    <div css="m-3">
      <div css="m-1 mb-4">
        <Chip color={getStatusColor(res.status)} variant="flat">
          {res.status}
        </Chip>{" "}
        {res.statusText || (
          <span css="text-sm italic opacity-60">
            {httpCodes.get(res.status) ?? "No status text"}
          </span>
        )}
      </div>
      <KVTable sort emptyContent="No headers" title="Headers" values={res.headers} />
    </div>
  )
}
