import { Moon, Sun, SunMoon } from "lucide-react"

import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "nextui"
import { useTheme } from "nextui/theme"
import { getSelectionKey } from "~/utils"

export function ThemeSwitch() {
  const { theme, setTheme } = useTheme()
  const Icon = theme === "dark" ? Moon : theme === "light" ? Sun : SunMoon

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button disableRipple isIconOnly variant="light">
          <Icon size={20} />
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        disallowEmptySelection
        aria-label="Theme switch"
        selectedKeys={[theme ?? "system"]}
        selectionMode="single"
        onSelectionChange={id => setTheme(getSelectionKey(id))}
      >
        <DropdownItem key="light">Light</DropdownItem>
        <DropdownItem key="dark">Dark</DropdownItem>
        <DropdownItem key="system">System</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
