import { useMemo } from "react"

import { MultipleSelect } from "../../components/Select"
import { count } from "../../toolbar/functions"
import { triggerStyle } from "../../toolbar/style"
import type { Har } from "../har"

export function MethodFilter({
  har,
  value,
  onChange,
}: {
  har?: Har
  value: string[]
  onChange: (value: React.SetStateAction<string[]>) => void
}) {
  const counts = useMemo(() => count(har?.log.entries, e => e.request.method), [har])

  function getLabel(key: string) {
    const count = counts.get(key)
    return count != null ? `${key} (${count})` : key
  }

  function getOption(key: string) {
    return { key, label: getLabel(key) }
  }

  return (
    <MultipleSelect
      aria-label="Method"
      classNames={{ trigger: triggerStyle }}
      options={Array.from(counts.keys()).map(getOption)}
      placeholder="Method"
      size="sm"
      value={value}
      variant="flat"
      onChange={onChange}
    />
  )
}
