import { cx as clsx, css } from "@emotion/css"
import { memo, useMemo, useState } from "react"

import { BreadcrumbItem, Breadcrumbs, Input } from "nextui"
import { Inspector } from "~/react-inspector"
import _ from "~/tokens"

const enum DOMQueryResult {
  NotSearching,
  Null,
  Success,
  SelectorError,
}

export const DOMView = memo(({ data, className }: { data: any; className?: string }) => {
  const [search, setSearch] = useState("")

  const document = useMemo(
    () => new DOMParser().parseFromString(data, "text/html"),
    [data]
  )

  const [state, queried] = useMemo(() => {
    if (!search) {
      return [DOMQueryResult.NotSearching, document]
    }

    try {
      const nodes = document.querySelectorAll(search)
      switch (nodes.length) {
        case 0:
          return [DOMQueryResult.Null, document]
        case 1:
          return [DOMQueryResult.Success, nodes[0]]
        default:
          // TODO: Fix multiple nodes
          return [DOMQueryResult.Success, nodes]
      }
    } catch {
      return [DOMQueryResult.SelectorError, document]
    }
  }, [document, search])

  ;(window as any).result = queried

  return (
    <div className="grid grid-rows-[auto_minmax(0,1fr)]">
      <Breadcrumbs className="mb-2 ml-1">
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem>Document</BreadcrumbItem>
      </Breadcrumbs>

      <div className={clsx(className, _.font_mono.text_sm._)}>
        <Inspector
          sortObjectKeys
          data={queried}
          expandLevel={11}
          expandPaths={[]}
          showNonEnumerable={false}
          table={false}
          name={
            state === DOMQueryResult.NotSearching
              ? "document"
              : state === DOMQueryResult.Null
                ? "(document)"
                : "length" in queried
                  ? "querySelectorAll()"
                  : "querySelector()"
          }
        />
      </div>
      <div>
        <Input
          className="w-full"
          placeholder="Query selector…"
          value={search}
          variant="bordered"
          classNames={{
            inputWrapper: css`
              border-width: 1px;
            `,
          }}
          style={
            state === DOMQueryResult.SelectorError
              ? { borderBottomColor: "var(--colorPaletteDarkRedBorderActive)" }
              : {}
          }
          onChange={e => setSearch(e.target.value)}
        />
      </div>
    </div>
  )
})
