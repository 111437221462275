import { css, cx } from "@emotion/css"

export const RootGrid = css`
  display: grid;
  background-color: light-dark(#fff, #232323);
  color: light-dark(#111, #eee);
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-areas: "top-bar" "center" "bottom-bar";
  height: 100vh;
  width: 100vw;
`

export const TopBar = cx(
  css`
    grid-area: top-bar;
    width: 100%; /* background: light-dark(#f0f0f0, #2f2f2f); */
    border-bottom: 0.5px solid light-dark(#bfb8b1, #070707);
  `,
  "bg-default-100"
)

export const Main = css`
  grid-area: center;
  width: 100vw;
`
