import { useMemo } from "react"

import { SingleSelect } from "../../components/Select"
import { count } from "../../toolbar/functions"
import { triggerStyle } from "../../toolbar/style"
import type { Har } from "../har"

export function StatusCodeFilter({
  har,
  value,
  onChange,
}: {
  har?: Har
  value: string | undefined
  onChange: (value: string | undefined) => void
}) {
  const counts = useMemo(
    () => count(har?.log.entries, e => +e.response.status.toString()[0]),
    [har]
  )

  function getLabel(key: number) {
    const count = counts.get(key)
    return count != null ? `${key}xx (${count})` : `${key}xx`
  }

  function getOption(key: number) {
    return { key: key.toString(), label: getLabel(key) }
  }

  return (
    <SingleSelect
      aria-label="Status Code"
      placeholder="Status Code"
      size="sm"
      value={value}
      variant="flat"
      classNames={{
        trigger: triggerStyle,
      }}
      options={[
        { key: "None", label: "All" },
        ...Array.from(counts.keys())
          .sort((a, b) => a - b)
          .map(getOption),
      ]}
      onChange={key => onChange(key === "None" ? undefined : key)}
    />
  )
}
