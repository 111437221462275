/* eslint-disable unicorn/consistent-destructuring */
// https://github.com/suren-atoyan/monaco-react/commit/f7cac39fbad0f062dc66458831aaf57a7126dd40
import type * as monaco from "monaco-editor/esm/vs/editor/editor.api"
import { memo, useCallback, useEffect, useRef, useState } from "react"

type Monaco = typeof monaco

// monaco-react/src/MonacoContainer/MonacoContainer.tsx
function MonacoContainer({
  ref,
  className,
  wrapperProps,
}: {
  ref: React.RefObject<HTMLDivElement>
  className?: string
  wrapperProps?: React.HTMLAttributes<HTMLElement>
}) {
  return (
    <section
      style={{
        display: "flex",
        position: "relative",
        textAlign: "initial",
        width: "100%",
        height: "100%",
        ...wrapperProps?.style,
      }}
      {...wrapperProps}
    >
      <div className={className} ref={ref} style={{ width: "100%" }} />
    </section>
  )
}

// monaco-react/src/MonacoContainer/index.ts

// monaco-react/src/hooks/useUpdate/index.ts
function useUpdate(
  effect: React.EffectCallback,
  deps: React.DependencyList,
  applyChanges: boolean
) {
  const isInitialMount = useRef(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    isInitialMount.current || !applyChanges
      ? () => {
          isInitialMount.current = false
        }
      : effect,
    deps
  )
}

/**
 * getOrCreateModel is a helper function that will return a model if it exists
 * or create a new model if it does not exist.
 * This is useful for when you want to create a model for a file that may or may not exist yet.
 * @param monaco The monaco instance
 * @param value The value of the model
 * @param language The language of the model
 * @param path The path of the model
 * @returns The model that was found or created
 */
function getOrCreateModel(monaco: Monaco, value: string, language: string, path: string) {
  return getModel(monaco, path) || createModel(monaco, value, language, path)
}

/**
 * getModel is a helper function that will return a model if it exists
 * or return undefined if it does not exist.
 * @param monaco The monaco instance
 * @param path The path of the model
 * @returns The model that was found or undefined
 */
function getModel(monaco: Monaco, path: string) {
  return monaco.editor.getModel(monaco.Uri.parse(path))
}

/**
 * createModel is a helper function that will create a new model
 * @param monaco The monaco instance
 * @param value The value of the model
 * @param language The language of the model
 * @param path The path of the model
 * @returns The model that was created
 */
function createModel(monaco: Monaco, value: string, language: string, path: string) {
  return monaco.editor.createModel(
    value,
    language,
    path ? monaco.Uri.parse(path) : undefined
  )
}

interface CommonProps {
  monaco: Monaco
  /** Class name for the editor container */
  className?: string
  /** Props applied to the wrapper element */
  wrapperProps?: React.HTMLAttributes<HTMLElement>
  /** Model language */
  language?: string

  /**
   * An event is emitted before the editor is mounted
   * It gets the monaco instance as a first argument
   * Defaults to "noop"
   */
  beforeMount?: () => void
}

// monaco-react/src/DiffEditor/DiffEditor.tsx
function DiffEditor(
  props: CommonProps & {
    /**
     * The original source (left one) value
     */
    original?: string

    /**
     * The modified source (right one) value
     */
    modified?: string

    /**
     * This prop gives you the opportunity to specify the language of the
     * original source separately, otherwise, it will get the value of the language property
     */
    originalLanguage?: string

    /**
     * This prop gives you the opportunity to specify the language of the
     * modified source separately, otherwise, it will get the value of language property
     */
    modifiedLanguage?: string

    /**
     * Path for the "original" model
     * Will be passed as a third argument to `.createModel` method
     * `monaco.editor.createModel(..., ..., monaco.Uri.parse(originalModelPath))`
     */
    originalModelPath?: string

    /**
     * Path for the "modified" model
     * Will be passed as a third argument to `.createModel` method
     * `monaco.editor.createModel(..., ..., monaco.Uri.parse(modifiedModelPath))`
     */
    modifiedModelPath?: string

    /**
     * Indicator whether to dispose the current original model when the DiffEditor is unmounted or not
     * @default false
     */
    keepCurrentOriginalModel?: boolean

    /**
     * Indicator whether to dispose the current modified model when the DiffEditor is unmounted or not
     * @default false
     */
    keepCurrentModifiedModel?: boolean

    /**
     * The theme for the monaco
     * Available options "vs-dark" | "light"
     * Define new themes by `monaco.editor.defineTheme`
     */
    theme?: string

    options?: monaco.editor.IDiffEditorConstructionOptions

    /**
     * An event is emitted when the editor is mounted
     * It gets the editor instance as a first argument.
     */
    onMount?: (editor: monaco.editor.IStandaloneDiffEditor) => void
  }
) {
  const {
    original,
    modified,
    monaco,
    language,
    originalLanguage,
    modifiedLanguage,
    originalModelPath,
    modifiedModelPath,
    keepCurrentOriginalModel = false,
    keepCurrentModifiedModel = false,
    theme = "vs",
    options,
    className,
    wrapperProps,
    beforeMount,
    onMount,
  } = props

  const [isEditorReady, setIsEditorReady] = useState(false)
  const editorRef = useRef<monaco.editor.IStandaloneDiffEditor | null>(null)
  const containerRef = useRef<HTMLDivElement>(undefined!)
  const onMountRef = useRef(onMount)
  const beforeMountRef = useRef(beforeMount)
  const preventCreation = useRef(false)

  useEffect(
    () => () => {
      const editor = editorRef.current
      if (editor) {
        const models = editor.getModel()
        if (!keepCurrentOriginalModel) {
          models?.original?.dispose()
        }
        if (!keepCurrentModifiedModel) {
          models?.modified?.dispose()
        }
        editor.dispose()
      }
    },
    []
  )

  useUpdate(
    () => {
      if (editorRef.current) {
        const originalEditor = editorRef.current.getOriginalEditor()
        const model = getOrCreateModel(
          monaco,
          original || "",
          originalLanguage || language || "text",
          originalModelPath || ""
        )
        if (model !== originalEditor.getModel()) {
          originalEditor.setModel(model)
        }
      }
    },
    [originalModelPath],
    isEditorReady
  )

  useUpdate(
    () => {
      if (editorRef.current) {
        const modifiedEditor = editorRef.current.getModifiedEditor()
        const model = getOrCreateModel(
          monaco,
          modified || "",
          modifiedLanguage || language || "text",
          modifiedModelPath || ""
        )
        if (model !== modifiedEditor.getModel()) {
          modifiedEditor.setModel(model)
        }
      }
    },
    [modifiedModelPath],
    isEditorReady
  )

  useUpdate(
    () => {
      const modifiedEditor = editorRef.current!.getModifiedEditor()
      if (modifiedEditor.getOption(monaco.editor.EditorOption.readOnly)) {
        modifiedEditor.setValue(modified || "")
      } else if (modified !== modifiedEditor.getValue()) {
        modifiedEditor.executeEdits("", [
          {
            range: modifiedEditor.getModel()!.getFullModelRange(),
            text: modified || "",
            forceMoveMarkers: true,
          },
        ])
        modifiedEditor.pushUndoStop()
      }
    },
    [modified],
    isEditorReady
  )

  useUpdate(
    () => {
      editorRef.current?.getModel()?.original.setValue(original || "")
    },
    [original],
    isEditorReady
  )

  useUpdate(
    () => {
      const { original, modified } = editorRef.current!.getModel()!
      monaco.editor.setModelLanguage(original, originalLanguage || language || "text")
      monaco.editor.setModelLanguage(modified, modifiedLanguage || language || "text")
    },
    [language, originalLanguage, modifiedLanguage],
    isEditorReady
  )

  useEffect(() => {
    monaco.editor.setTheme(theme)
  }, [monaco.editor, theme])

  useUpdate(
    () => {
      editorRef.current?.updateOptions(options ?? {})
    },
    [options],
    isEditorReady
  )

  const setModels = useCallback(() => {
    beforeMountRef.current?.()
    const originalModel = getOrCreateModel(
      monaco,
      original || "",
      originalLanguage || language || "text",
      originalModelPath || ""
    )
    const modifiedModel = getOrCreateModel(
      monaco,
      modified || "",
      modifiedLanguage || language || "text",
      modifiedModelPath || ""
    )
    editorRef.current?.setModel({
      original: originalModel,
      modified: modifiedModel,
    })
  }, [
    language,
    monaco,
    modified,
    modifiedLanguage,
    original,
    originalLanguage,
    originalModelPath,
    modifiedModelPath,
  ])

  const createEditor = useCallback(() => {
    if (!preventCreation.current && containerRef.current) {
      editorRef.current = monaco.editor.createDiffEditor(containerRef.current, {
        automaticLayout: true,
        ...options,
      })
      setModels()
      monaco.editor.setTheme(theme)
      setIsEditorReady(true)
      preventCreation.current = true
    }
  }, [options, theme, setModels, monaco.editor])

  useEffect(() => {
    if (isEditorReady) {
      onMountRef.current?.(editorRef.current!)
    }
  }, [isEditorReady])

  useEffect(() => {
    if (!isEditorReady) {
      createEditor()
    }
  }, [isEditorReady, createEditor])

  return (
    <MonacoContainer
      ref={containerRef}
      {...{
        isEditorReady,
        className,
        wrapperProps,
      }}
    />
  )
}

// monaco-react/src/DiffEditor/index.ts
const MemoDiffEditor = memo(DiffEditor)

// monaco-react/src/hooks/usePrevious/index.ts
function usePrevious<T>(value: T) {
  const ref = useRef<T>(undefined!)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

// monaco-react/src/Editor/Editor.tsx
const viewStates = new Map<string, monaco.editor.ICodeEditorViewState>()

function Editor(
  props: CommonProps & {
    /**
     * Default value of the current model
     */
    defaultValue?: string

    /**
     * Default language of the current model
     */
    defaultLanguage?: string

    /**
     * Default path of the current model
     * Will be passed as the third argument to `.createModel` method
     * `monaco.editor.createModel(..., ..., monaco.Uri.parse(defaultPath))`
     */
    defaultPath?: string

    /**
     * Value of the current model
     */
    value?: string

    /**
     * Path of the current model
     * Will be passed as the third argument to `.createModel` method
     * `monaco.editor.createModel(..., ..., monaco.Uri.parse(defaultPath))`
     */
    path?: string

    /**
     * The line to jump on it
     */
    line?: number

    /**
     * IStandaloneEditorConstructionOptions
     */
    options?: monaco.editor.IStandaloneEditorConstructionOptions

    /**
     * IEditorOverrideServices
     */
    overrideServices?: monaco.editor.IEditorOverrideServices

    /**
     * Indicator whether to save the models' view states between model changes or not
     * Defaults to true
     */
    saveViewState?: boolean

    /**
     * Indicator whether to dispose the current model when the Editor is unmounted or not
     * @default false
     */
    keepCurrentModel?: boolean

    /**
     * Class name for the editor container
     */
    className?: string

    /**
     * Props applied to the wrapper element
     */
    wrapperProps?: React.HTMLAttributes<HTMLElement>

    /**
     * Signature: function(value: string | undefined, ev: monaco.editor.IModelContentChangedEvent) => void
     * An event is emitted when the content of the current model is changed
     */
    onChange?: (
      value: string | undefined,
      ev: monaco.editor.IModelContentChangedEvent
    ) => void

    /**
     * Signature: function(markers: monaco.editor.IMarker[]) => void
     * An event is emitted when the content of the current model is changed
     * and the current model markers are ready
     * Defaults to "noop"
     */
    onValidate?: (markers: monaco.editor.IMarker[]) => void

    /**
     * An event is emitted when the editor is mounted
     * It gets the editor instance as a first argument.
     */
    onMount?: (editor: monaco.editor.IStandaloneCodeEditor) => void

    editorRef?: React.Ref<monaco.editor.IStandaloneCodeEditor | undefined>
  }
) {
  const {
    monaco,
    defaultValue,
    defaultLanguage,
    defaultPath,
    value,
    language,
    path,
    /* === */
    line,
    options,
    overrideServices,
    saveViewState = true,
    keepCurrentModel = false,
    /* === */
    className,
    wrapperProps,
    /* === */
    beforeMount,
    onMount,
    onChange,
    onValidate,
    editorRef: editorRefProp,
  } = props

  const theme = options?.theme ?? "vs"

  const [isEditorReady, setIsEditorReady] = useState(false)
  const containerRef = useRef<HTMLDivElement>(undefined!)
  const onMountRef = useRef(onMount)
  const beforeMountRef = useRef(beforeMount)
  const previousPath = usePrevious(path)

  const { current: self } = useRef<{
    editor: monaco.editor.IStandaloneCodeEditor | null
    subscription: monaco.IDisposable | undefined
    preventCreation: boolean
    preventTriggerChangeEvent: boolean
  }>({
    editor: null,
    subscription: undefined,
    preventCreation: false,
    preventTriggerChangeEvent: false,
  })

  useEffect(
    () => () => {
      const { editor, subscription } = self
      if (!editor || previousPath == null) return

      subscription?.dispose()
      if (keepCurrentModel) {
        if (saveViewState) {
          viewStates.set(path!, editor.saveViewState()!)
        }
      } else {
        editor.getModel()?.dispose()
      }
      editor.dispose()
    },
    []
  )

  useEffect(() => {
    const { editor } = self
    if (!editor) return

    const model = getOrCreateModel(
      monaco,
      defaultValue || value || "",
      defaultLanguage || language || "",
      path || defaultPath || ""
    )

    if (model !== editor.getModel()) {
      if (saveViewState) {
        viewStates.set(previousPath!, editor.saveViewState()!)
      }
      editor.setModel(model)
      if (saveViewState) {
        editor.restoreViewState(viewStates.get(path!)!)
      }
    }
  }, [path, self])

  useEffect(() => {
    self.editor?.updateOptions(options ?? {})
  }, [options, self])

  useEffect(() => {
    const { editor } = self
    if (!editor || value === undefined) return

    if (editor.getOption(monaco.editor.EditorOption.readOnly)) {
      editor.setValue(value)
    } else if (value !== editor.getValue()) {
      self.preventTriggerChangeEvent = true
      editor.executeEdits("", [
        {
          range: editor.getModel()!.getFullModelRange(),
          text: value,
          forceMoveMarkers: true,
        },
      ])
      editor.pushUndoStop()
      self.preventTriggerChangeEvent = false
    }
  }, [value, monaco.editor.EditorOption.readOnly, self])

  useUpdate(
    () => {
      const model = self.editor?.getModel()
      if (model && language) monaco.editor.setModelLanguage(model, language)
    },
    [language],
    isEditorReady
  )

  useUpdate(
    () => {
      if (line !== undefined) {
        self.editor?.revealLine(line)
      }
    },
    [line],
    isEditorReady
  )

  useEffect(() => {
    monaco.editor.setTheme(theme)
  }, [theme, monaco.editor])

  const createEditor = useCallback(() => {
    if (!containerRef.current) return
    if (!self.preventCreation) {
      beforeMountRef.current?.()
      const autoCreatedModelPath = path || defaultPath!
      const defaultModel = getOrCreateModel(
        monaco,
        value || defaultValue || "",
        defaultLanguage || language || "",
        autoCreatedModelPath || ""
      )
      const editor = (self.editor = monaco.editor.create(
        containerRef.current,
        {
          model: defaultModel,
          automaticLayout: true,
          ...options,
        },
        overrideServices
      ))
      if (saveViewState) {
        editor.restoreViewState(viewStates.get(autoCreatedModelPath)!)
      }
      monaco.editor.setTheme(theme)
      if (line !== undefined) {
        editor.revealLine(line)
      }
      setIsEditorReady(true)
      self.preventCreation = true
    }
  }, [
    defaultValue,
    defaultLanguage,
    defaultPath,
    value,
    language,
    path,
    options,
    overrideServices,
    saveViewState,
    theme,
    line,
    monaco,
    self,
  ])

  if (typeof editorRefProp === "function") {
    editorRefProp(self.editor)
  } else if (editorRefProp && typeof editorRefProp === "object") {
    editorRefProp.current = self.editor
  }

  useEffect(() => {
    if (isEditorReady) {
      onMountRef.current?.(self.editor!)
    }
  }, [isEditorReady, self])

  useEffect(() => {
    if (!isEditorReady) {
      createEditor()
    }
  }, [isEditorReady, createEditor])

  useEffect(() => {
    if (isEditorReady && onChange) {
      const { editor } = self
      self.subscription?.dispose()
      self.subscription = editor?.onDidChangeModelContent(event => {
        if (!self.preventTriggerChangeEvent) {
          onChange(editor.getValue(), event)
        }
      })
    }
  }, [isEditorReady, onChange, self])

  useEffect(() => {
    if (isEditorReady) {
      const changeMarkersListener = monaco.editor.onDidChangeMarkers(uris => {
        const resource = self.editor!.getModel()?.uri
        if (!resource) return

        const currentEditorHasMarkerChanges = uris.find(uri => uri.path === resource.path)
        if (currentEditorHasMarkerChanges) {
          onValidate?.(monaco.editor.getModelMarkers({ resource }))
        }
      })
      return () => {
        changeMarkersListener?.dispose()
      }
    }
    return () => {}
  }, [isEditorReady, onValidate, monaco.editor, self])

  return (
    <MonacoContainer
      className={className}
      ref={containerRef}
      wrapperProps={wrapperProps}
    />
  )
}

// monaco-react/src/Editor/index.ts
const MemoEditor = memo(Editor)

// monaco-react/src/index.ts

export { MemoDiffEditor as DiffEditor, MemoEditor as Editor }
