import { css } from "@emotion/css"

export function FileIcon({
  mimeType,
  fallback,
  className,
}: {
  mimeType?: string
  fallback?: React.ReactNode
  className?: string
}) {
  if (!mimeType) return null

  const icon = fileIcon({ mimeType }) ?? fallback
  return icon ? (
    <span className={className} css="mr-0.5">
      {icon}
    </span>
  ) : null
}

const CSS = (
  <svg
    viewBox="4 4 25 25"
    className={css`
      margin-left: -1px;
      margin-right: -4px;
      width: 1.5em;
    `}
  >
    <path
      d="M10.3 23.3l.8-4H8.6v-2.1h3l.5-2.5H9.5v-2.1h3.1l.8-3.9h2.8l-.8 3.9h2.8l.8-3.9h2.8l-.8 3.9h2.5v2.1h-2.9l-.6 2.5h2.6v2.1h-3l-.8 4H16l.8-4H14l-.8 4h-2.9zm6.9-6.1l.5-2.5h-2.8l-.5 2.5h2.8z"
      fill="#529BBA"
    />
  </svg>
)

const JSON = (
  <svg
    viewBox="4 3 25 25"
    xmlns="http://www.w3.org/2000/svg"
    className={css`
      margin-left: -1px;
      margin-right: -4px;
      width: 1.5em;
    `}
  >
    <path
      d="M7.5 15.1c1.5 0 1.7-.8 1.7-1.5 0-.6-.1-1.1-.1-1.7S9 10.7 9 10.2c0-2.1 1.3-3 3.4-3h.8v1.9h-.4c-1 0-1.3.6-1.3 1.6 0 .4.1.8.1 1.3 0 .4.1.9.1 1.5 0 1.7-.7 2.3-1.9 2.6 1.2.3 1.9.9 1.9 2.6 0 .6-.1 1.1-.1 1.5 0 .4-.1.9-.1 1.2 0 1 .3 1.6 1.3 1.6h.4v1.9h-.8c-2 0-3.3-.8-3.3-3 0-.6 0-1.1.1-1.7.1-.6.1-1.2.1-1.7 0-.6-.2-1.5-1.7-1.5l-.1-1.9zm17 1.7c-1.5 0-1.7.9-1.7 1.5s.1 1.1.1 1.7c.1.6.1 1.2.1 1.7 0 2.2-1.4 3-3.4 3h-.8V23h.4c1 0 1.3-.6 1.3-1.6 0-.4 0-.8-.1-1.2 0-.5-.1-1-.1-1.5 0-1.7.7-2.3 1.9-2.6-1.2-.3-1.9-.9-1.9-2.6 0-.6.1-1.1.1-1.5.1-.5.1-.9.1-1.3 0-1-.4-1.5-1.3-1.6h-.4V7.2h.8c2.1 0 3.4.9 3.4 3 0 .6-.1 1.1-.1 1.7-.1.6-.1 1.2-.1 1.7 0 .7.2 1.5 1.7 1.5v1.7z"
      fill="#DBCD68"
    />
  </svg>
)

export const JavaScript = (
  <svg style={{ marginLeft: -1, marginRight: -4, width: "1.5em" }} viewBox="5 4 23 24">
    <path
      d="M11.4 10h2.7v7.6c0 3.4-1.6 4.6-4.3 4.6-.6 0-1.5-.1-2-.3l.3-2.2c.4.2.9.3 1.4.3 1.1 0 1.9-.5 1.9-2.4V10zm5.1 9.2c.7.4 1.9.8 3 .8 1.3 0 1.9-.5 1.9-1.3s-.6-1.2-2-1.7c-2-.7-3.3-1.8-3.3-3.6 0-2.1 1.7-3.6 4.6-3.6 1.4 0 2.4.3 3.1.6l-.6 2.2c-.5-.2-1.3-.6-2.5-.6s-1.8.5-1.8 1.2c0 .8.7 1.1 2.2 1.7 2.1.8 3.1 1.9 3.1 3.6 0 2-1.6 3.7-4.9 3.7-1.4 0-2.7-.4-3.4-.7l.6-2.3z"
      fill="light-dark(#c0ad22, #F1DD3F)"
    />
  </svg>
)

const PDF = (
  <svg
    style={{ marginLeft: -1, marginRight: -4, width: "1.5em" }}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 22.9c.1-.6.5-1 .9-1.4.5-.5 1.1-.8 1.8-1.2.7-.4 1.4-.7 2.1-1 .1 0 .2-.1.2-.2.6-1.2 1.2-2.4 1.7-3.6.3-.7.5-1.4.8-2.1v-.1c-.3-.7-.6-1.5-.7-2.3-.2-.8-.2-1.6-.1-2.4.1-.5.4-.9.8-1.3.1-.1.3-.1.5-.1h.8c.2 0 .4.1.5.3.3.2.5.5.7.8.2.4.2.8.3 1.2 0 1.2-.2 2.3-.4 3.4-.1.4-.2.7-.3 1.1v.1c.6 1.1 1.4 2.1 2.2 3 .1.1.1.1.3.1 1.1-.2 2.2-.2 3.2-.2.6 0 1.3.1 1.9.4.3.2.6.4.8.7.1.2.2.4.2.6v.7c0 .2-.1.4-.3.5-.2.2-.4.5-.8.5-.2 0-.5.1-.7.1-1.6.1-2.9-.4-4.2-1.3-.2-.2-.5-.4-.7-.6-.1 0-.1-.1-.2-.1-.6.1-1.2.2-1.8.4-.8.2-1.6.5-2.4.7-.1 0-.1.1-.2.1-.5.9-1.1 1.8-1.7 2.6-.5.6-1.1 1.2-1.7 1.7-.3.2-.7.4-1.1.5h-.8c-.2 0-.3 0-.5-.1-.5-.2-.9-.6-1-1.1-.1 0-.1-.2-.1-.4zm8.8-7c-.3.8-.7 1.6-1 2.4l2.4-.6c-.5-.6-1-1.3-1.4-1.8zm4.3 2.6c.6.4 1.3.7 2 .9.3.1.5 0 .7-.1.2-.1.3-.4.1-.5 0-.1-.1-.1-.2-.1-.2-.1-.5-.1-.8-.2-.6-.1-1.2-.1-1.8 0zm-9.4 2.8s-.1 0 0 0c-.6.3-1.2.7-1.7 1.1-.3.2-.5.5-.7.8v.2c.1.1.1.1.2.1.3-.2.5-.4.7-.5.6-.5 1-1.1 1.5-1.7zM15 11.2c.1 0 .1 0 0 0 .2-.6.3-1.2.3-1.7 0-.3 0-.6-.1-.9 0-.1-.1-.1-.2-.1s-.1.1-.2.1c-.2.3-.2.6-.2 1 0 .3 0 .5.1.8.2.2.2.5.3.8z" />
  </svg>
)

const WebFont = (
  <svg
    viewBox="4 4 23 23"
    className={css`
      fill: light-dark(#000, #fff);
      margin-left: -1px;
      margin-right: -4px;
      width: 1.5em;
    `}
  >
    <path
      d="M21.1 18.3h-3.3l-.4.9c-.1.3-.2.5-.2.7 0 .3.1.5.3.6.1.1.4.1.9.2v.3h-3.1v-.3c.3-.1.6-.2.8-.4s.5-.7.8-1.4l3.3-7.4h.1l3.3 7.6c.3.7.6 1.2.8 1.4.2.1.4.2.7.2v.3h-4.5v-.3h.4c.4 0 .6-.1.8-.2.1-.1.2-.2.2-.3v-.3s-.1-.2-.2-.5l-.7-1.1zm-.2-.6l-1.4-3.2-1.4 3.2h2.8z"
      opacity=".6"
    />
    <path d="M6.7 22.4c.5 0 .9-.3 1.2-.7.2-.3.5-.8.8-1.7l4.4-10.9h.6l4.5 10.5c.5 1.2.9 1.9 1.1 2.3s.6.5 1.1.5v.5h-6.5v-.5c.7 0 1.1-.1 1.3-.2.2-.1.3-.3.3-.6 0-.2-.1-.4-.2-.7-.1-.2-.2-.4-.3-.7l-.5-1.2H10c-.3.8-.5 1.3-.6 1.6-.2.5-.3.9-.3 1.1 0 .3.2.5.6.6.2.1.6.1 1 .1v.5h-4v-.5zm7.5-4.2l-1.9-4.6h-.2l-1.8 4.6h3.9z" />
  </svg>
)

const HTML = (
  <svg
    viewBox="1 2 28 28"
    className={css`
      margin-left: -2px;
      margin-right: -4px;
      width: 1.5em;
    `}
  >
    <path
      d="M8 15l6-5.6V12l-4.5 4 4.5 4v2.6L8 17v-2zm16 2.1l-6 5.6V20l4.6-4-4.6-4V9.3l6 5.6v2.2z"
      fill="#EF7623"
    />
  </svg>
)

const Image = (
  <svg
    viewBox="2 2 26 26"
    xmlns="http://www.w3.org/2000/svg"
    className={css`
      margin-left: -2px;
      margin-right: -4px;
      width: 1.5em;
    `}
  >
    <path
      d="M21.3 17.2c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8-1.8.8-1.8 1.8.8 1.8 1.8 1.8zm-11.1-5.5v12.4h15.3V11.7H10.2zm.7.7h13.9v10.8l-3.6-4.1-2.2 2.6-4.4-4.7-3.7 4.6v-9.2zm9.8-4.5H6.5v10.8h1.9V9.6h12.3V7.9z"
      fill="#9F74B3"
    />
  </svg>
)

function fileIcon({ mimeType }: { mimeType: string }) {
  if (mimeType.startsWith("image/")) {
    return Image
  } else if (mimeType.startsWith("font/")) {
    return WebFont
  } else if (mimeType.startsWith("text/css")) {
    return CSS
  } else if (mimeType.includes("javascript")) {
    return JavaScript
  } else if (mimeType.includes("text/html")) {
    return HTML
  } else if (mimeType.includes("json")) {
    return JSON
  } else if (mimeType.includes("pdf")) {
    return PDF
  }

  return null
}
