import { css, cx } from "@emotion/css"
import { memo } from "react"

import { domIterator, shouldInline } from "./iterators/DOM"
import { TREENODE_PADDING_LEFT, lightDark } from "./tokens"
import { type NodeRenderer, TreeView, type TreeViewProps } from "./TreeView"

export type DOMInspectorProps = Omit<TreeViewProps, "nodeRenderer" | "dataIterator">

export const DOMInspector = (props: DOMInspectorProps) => (
  <div className="ml-1.5">
    <TreeView dataIterator={domIterator} nodeRenderer={DOMNodePreview} {...props} />
  </div>
)

const HTML_TAG_COLOR = lightDark("#a894a6", "#5db0d7")
export const HTML_TAGNAME_COLOR = lightDark("#881280", "#5db0d7")
export const HTML_ATTRIBUTE_NAME_COLOR = lightDark("#994500", "#9bbbdc")
const HTML_ATTRIBUTE_VALUE_COLOR = lightDark("#1a1aa6", "#f29766")
const HTML_COMMENT_COLOR = lightDark("#236e25", "#898989")
const HTML_DOCTYPE_COLOR = "#c0c0c0"

const OpenTag = memo(
  ({ tagName, attributes }: { tagName: string; attributes?: NamedNodeMap }) => (
    <span className={css({ color: HTML_TAG_COLOR })}>
      {"<"}
      <span
        className={css({
          color: HTML_TAGNAME_COLOR,
          textTransform: "lowercase",
        })}
      >
        {tagName}
      </span>

      {(() => {
        if (attributes) {
          return Array.from(attributes).map(({ name, value }) => (
            <span key={name}>
              {" "}
              <span className={css({ color: HTML_ATTRIBUTE_NAME_COLOR })}>{name}</span>
              {'="'}
              <span className={css({ color: HTML_ATTRIBUTE_VALUE_COLOR })}>{value}</span>
              {'"'}
            </span>
          ))
        }
      })()}
      {">"}
    </span>
  )
)

// isChildNode style={{ marginLeft: -12 /* hack: offset placeholder */ }}
function CloseTag({
  tagName,
  isChildNode = false,
}: {
  tagName: string
  isChildNode?: boolean
}) {
  return (
    <span
      className={cx(
        css({
          color: HTML_TAG_COLOR,
        }),
        isChildNode &&
          css({
            /* hack: offset placeholder */
            marginLeft: -TREENODE_PADDING_LEFT,
          })
      )}
    >
      {"</"}
      <span
        className={css({
          color: HTML_TAGNAME_COLOR,
          textTransform: "lowercase",
        })}
      >
        {tagName}
      </span>
      {">"}
    </span>
  )
}

const nameByNodeType: {
  [key: number]: string
} = {
  1: "ELEMENT_NODE",
  3: "TEXT_NODE",
  7: "PROCESSING_INSTRUCTION_NODE",
  8: "COMMENT_NODE",
  9: "DOCUMENT_NODE",
  10: "DOCUMENT_TYPE_NODE", // http://stackoverflow.com/questions/6088972/get-doctype-of-an-html-as-string-with-javascript
  11: "DOCUMENT_FRAGMENT_NODE",
}

const DOMNodePreview: NodeRenderer = memo(({ isCloseTag, data, expanded }) => {
  if (isCloseTag) {
    return <CloseTag isChildNode tagName={(data as Element).tagName} />
  }

  switch (data.nodeType) {
    case Node.ELEMENT_NODE:
      return (
        <span>
          <OpenTag
            tagName={(data as Element).tagName}
            attributes={(data as Element).attributes}
          />

          {shouldInline(data) ? data.textContent : !expanded && "…"}

          {!expanded && <CloseTag tagName={(data as Element).tagName} />}
        </span>
      )
    case Node.TEXT_NODE:
      return <span>{data.textContent}</span>
    case Node.CDATA_SECTION_NODE:
      return <span>{"<![CDATA[" + data.textContent + "]]>"}</span>
    case Node.COMMENT_NODE:
      return (
        <span className={css({ color: HTML_COMMENT_COLOR })}>
          {"<!--"}
          {data.textContent}
          {"-->"}
        </span>
      )
    case Node.PROCESSING_INSTRUCTION_NODE:
      return <span>{data.nodeName}</span>
    case Node.DOCUMENT_TYPE_NODE:
      return (
        <span className={css({ color: HTML_DOCTYPE_COLOR })}>
          {"<!DOCTYPE "}
          {(data as DocumentType).name}
          {(data as DocumentType).publicId
            ? ` PUBLIC "${(data as DocumentType).publicId}"`
            : ""}
          {!(data as DocumentType).publicId && (data as DocumentType).systemId
            ? " SYSTEM"
            : ""}
          {(data as DocumentType).systemId ? ` "${(data as DocumentType).systemId}"` : ""}
          {">"}
        </span>
      )
    case Node.DOCUMENT_NODE:
      return <span>{data.nodeName}</span>
    case Node.DOCUMENT_FRAGMENT_NODE:
      return <span>{data.nodeName}</span>
    default:
      return <span>{nameByNodeType[data.nodeType]}</span>
  }
})
