import { css, cx } from "@emotion/css"
import { memo, useCallback, useState } from "react"

import { ObjectValue } from "./Object"
import { BASE_COLOR, lightDark } from "./tokens"
import { asKey } from "./utils"

const TABLE_BORDER_COLOR = lightDark("#aaa", "#555")
const TABLE_TH_BACKGROUND_COLOR = lightDark("#eee", "#2c2c2c")
const TABLE_TH_HOVER_COLOR = lightDark("#e6e6e6", "#303030")
const TABLE_SORT_ICON_COLOR = lightDark("#6e6e6e", "#000")

const TableInspectorLeftBorder = {
  none: css`
    border-left: none;
  `,
  solid: css`
    border-left: 1px solid ${TABLE_BORDER_COLOR};
  `,
}

const TableInspectorDataContainer = {
  tr: css`
    display: table-row;
  `,
  td: css`
    box-sizing: border-box;
    border: none; // prevent overrides
    height: 16px; // /* 0.5 * table.background-size height */
    vertical-align: top;
    padding: 1px 4px;
    user-select: text;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 14px;
  `,
  div: css`
    position: static;
    top: 17px;
    bottom: 0;
    overflow-y: overlay;
    transform: translateZ(0);
    left: 0;
    right: 0;
    overflow-x: hidden;
  `,
  table: css`
    position: static;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-top: 0 none transparent;
    margin: 0; // prevent user agent stylesheet overrides

    background-image: linear-gradient(
      to bottom,
      light-dark(#fff, rgba(255, 255, 255, 0)),
      light-dark(#fff, rgba(255, 255, 255, 0)) 50%,
      light-dark(rgb(234, 243, 255), rgba(51, 139, 255, 0.0980392)) 50%,
      light-dark(rgb(234, 243, 255), rgba(51, 139, 255, 0.0980392))
    );
    background-size: 128px 32px;
    table-layout: fixed;

    // table
    border-spacing: 0;
    border-collapse: separate;
    // height: '100%';
    width: 100%;

    line-height: 120%;
  `,
}

const DataContainer = memo<{
  rows: PropertyKey[]
  columns: PropertyKey[]
  rowsData: unknown[]
}>(({ rows, columns, rowsData }) => {
  const styles = TableInspectorDataContainer
  const borderStyles = TableInspectorLeftBorder

  return (
    <div className={styles.div}>
      <table className={styles.table}>
        <colgroup />
        <tbody>
          {rows.map((row, i) => (
            <tr key={asKey(row)} className={styles.tr}>
              <td className={cx(styles.td, borderStyles.none)}>{asKey(row)}</td>

              {columns.map(column => {
                const rowData = rowsData[i]
                // rowData could be
                //  object → index by key
                //    array → index by array index
                //    null → pass
                //  boolean → pass
                //  string → pass (hasOwnProperty returns true for [0..len-1])
                //  number → pass
                //  function → pass
                //  symbol
                //  undefined → pass
                return typeof rowData === "object" &&
                  rowData !== null &&
                  Object.hasOwn(rowData, column) ? (
                  <td key={asKey(column)} className={cx(styles.td, borderStyles.solid)}>
                    <ObjectValue object={(rowData as any)[column]} />
                  </td>
                ) : (
                  <td key={asKey(column)} className={cx(styles.td, borderStyles.solid)} />
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
})

function getHeaders(data: Record<string, unknown> | unknown[]) {
  if (typeof data === "object") {
    let rowHeaders: PropertyKey[] = []
    // is an array
    if (Array.isArray(data)) {
      const nRows = data.length
      rowHeaders = [...Array(nRows).keys()]
    } else if (data !== null) {
      // is an object
      // keys are row indexes
      rowHeaders = (Object.keys(data) as PropertyKey[]).concat(
        Object.getOwnPropertySymbols(data)
      )
    }

    // Time: O(nRows * nCols)
    const colHeaders = rowHeaders.reduce((colHeaders, rowHeader) => {
      const row = (data as any)[rowHeader]
      if (typeof row === "object" && row !== null) {
        /* O(nCols) Could optimize `includes` here */
        const cols = Object.keys(row)
        cols.reduce((xs, x) => {
          if (!xs.includes(x)) {
            /* xs is the colHeaders to be filled by searching the row's indexes */
            xs.push(x)
          }
          return xs
        }, colHeaders)
      }
      return colHeaders
    }, [] as PropertyKey[])

    return {
      rowHeaders,
      colHeaders,
    }
  }

  return
}

const HeaderContainer = memo<{
  indexColumnText?: string
  columns: PropertyKey[]
  sorted: boolean
  sortIndexColumn: boolean
  sortColumn?: PropertyKey
  sortAscending: boolean
  onTHClick: (column: PropertyKey) => void
  onIndexTHClick: () => void
}>(props => {
  const {
    indexColumnText = "(index)",
    columns = [],
    sorted,
    sortIndexColumn,
    sortColumn,
    sortAscending,
    onTHClick,
    onIndexTHClick,
  } = props

  const styles = {
    base: css`
      top: 0;
      height: 17px;
      left: 0;
      right: 0;
      overflow-x: hidden;
    `,
    table: css`
      table-layout: fixed;
      border-spacing: 0;
      border-collapse: separate;
      height: 100%;
      width: 100%;
      margin: 0;
    `,
  }
  const borderStyles = TableInspectorLeftBorder

  return (
    <div className={styles.base}>
      <table className={styles.table}>
        <tbody>
          <tr>
            <TH
              borderClassName={borderStyles.none}
              sorted={sorted && sortIndexColumn}
              sortAscending={sortAscending}
              onClick={onIndexTHClick}
            >
              {indexColumnText}
            </TH>
            {columns.map(column => (
              <TH
                borderClassName={borderStyles.solid}
                key={asKey(column)}
                sorted={sorted && sortColumn === column}
                sortAscending={sortAscending}
                onClick={() => onTHClick(column)}
              >
                {asKey(column)}
              </TH>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  )
})

/**
 * Specs:
 * https://developer.chrome.com/devtools/docs/commandline-api#tabledata-columns
 * https://developer.mozilla.org/en-US/docs/Web/API/Console/table
 */

// use '<' operator to compare same type of values or compare type precedence order #
function lt(v1: any, v2: any) {
  if (v1 < v2) {
    return -1
  } else if (v1 > v2) {
    return 1
  } else {
    return 0
  }
}
export interface TableInspectorProps {
  /** the Javascript object you would like to inspect, either an array or an object */
  data: Record<string, any> | any[]
  /** An array of the names of the columns you'd like to display in the table */
  columns?: string[]
}

type ColumnDataWithRowIndex = [PropertyKey | undefined, number]

export const TableInspector = memo<TableInspectorProps>(({ data, columns }) => {
  // has user ever clicked the <th> tag to sort?
  const [sorted, setSorted] = useState(false)
  // is index column sorted?
  const [sortIndexColumn, setSortIndexColumn] = useState(false)
  // which column is sorted?
  const [sortColumn, setSortColumn] = useState<PropertyKey>()
  // is sorting ascending or descending?
  const [sortAscending, setSortAscending] = useState(false)

  const handleIndexTHClick = useCallback(() => {
    setSorted(true)
    setSortIndexColumn(true)
    setSortColumn(undefined)
    // when changed to a new column, default to ascending
    setSortAscending(sortIndexColumn ? !sortAscending : true)
  }, [sortIndexColumn, sortAscending])

  const handleTHClick = useCallback(
    (col: PropertyKey) => {
      setSorted(true)
      setSortIndexColumn(false)
      // update sort column
      setSortColumn(col)
      // when changed to a new column, default to ascending
      setSortAscending(col === sortColumn ? !sortAscending : true)
    },
    [sortAscending, sortColumn]
  )

  if (typeof data !== "object" || data === null) {
    return <div />
  }

  let { rowHeaders, colHeaders } = getHeaders(data)!

  // columns to be displayed are specified
  // NOTE: there's some space for optimization here
  if (columns !== undefined) {
    colHeaders = columns
  }

  let rowsData: unknown[] = rowHeaders.map(rowHeader => (data as any)[rowHeader])

  let columnDataWithRowIndexes: ColumnDataWithRowIndex[] | undefined
  // TODO: refactor
  if (sortColumn !== undefined) {
    // the column to be sorted (rowsData, column) => [[columnData, rowIndex]]
    columnDataWithRowIndexes = rowsData.map((rowData, index: number) => {
      // normalize rowData
      if (
        typeof rowData === "object" &&
        rowData !== null /* && rowData.hasOwnProperty(sortColumn)*/
      ) {
        const columnData = (rowData as any)[sortColumn]
        return [columnData, index]
      }
      return [undefined, index]
    })
  } else if (sortIndexColumn) {
    columnDataWithRowIndexes = rowHeaders.map((_rowData, index: number) => {
      const columnData = rowHeaders[index]
      return [columnData, index]
    })
  }
  if (columnDataWithRowIndexes !== undefined) {
    // apply a mapper before sorting (because we need to access inside a container)
    const comparator =
      (mapper: (data: ColumnDataWithRowIndex) => unknown, ascending: boolean) =>
      (a: ColumnDataWithRowIndex, b: ColumnDataWithRowIndex) => {
        const v1 = mapper(a) // the datum
        const v2 = mapper(b)
        const type1 = typeof v1
        const type2 = typeof v2

        let result
        if (type1 === type2) {
          result = lt(v1, v2)
        } else {
          // order of different types
          const order = {
            string: 0,
            number: 1,
            object: 2,
            symbol: 3,
            boolean: 4,
            undefined: 5,
            function: 6,
            bigint: 7,
          }
          result = lt(order[type1], order[type2])
        }
        // reverse result if descending
        if (!ascending) result = -result
        return result
      }
    const sortedRowIndexes = columnDataWithRowIndexes
      .sort(comparator(item => item[0], sortAscending))
      .map(item => item[1]) // sorted row indexes
    rowHeaders = sortedRowIndexes.map(i => rowHeaders[i])
    rowsData = sortedRowIndexes.map(i => rowsData[i])
  }

  return (
    <div
      className={css`
        color: ${BASE_COLOR};
        position: relative;
        border: 1px solid ${TABLE_BORDER_COLOR};
        font-family: D2Coding, "SF Mono", Menlo, monospace;
        line-height: 120%;
        box-sizing: border-box;
        cursor: default;
      `}
    >
      <HeaderContainer
        columns={colHeaders}
        /* for sorting */
        sorted={sorted}
        sortIndexColumn={sortIndexColumn}
        sortColumn={sortColumn}
        sortAscending={sortAscending}
        onTHClick={handleTHClick}
        onIndexTHClick={handleIndexTHClick}
      />
      <DataContainer rows={rowHeaders} columns={colHeaders} rowsData={rowsData} />
    </div>
  )
})

const SortIconContainer = memo<{
  children: React.ReactNode
}>(({ children }) => (
  <div
    className={css({
      position: "absolute",
      top: 1,
      right: 0,
      bottom: 1,
      display: "flex",
      alignItems: "center",
    })}
  >
    {children}
  </div>
))

const SortIcon = memo<{
  sortAscending: boolean
}>(({ sortAscending }) => {
  const styles = css`
    display: block;
    margin-right: 3; // 4;
    width: 8;
    height: 7;

    margin-top: -7;
    color: ${TABLE_SORT_ICON_COLOR};
    font-size: 12px;
    // lineHeight: 14
    user-select: none;
  `
  const glyph = sortAscending ? "▲" : "▼"
  return <div className={styles}>{glyph}</div>
})

const TH = memo<{
  sortAscending?: boolean
  sorted?: boolean
  onClick?: () => void
  borderClassName?: string
  children: React.ReactNode
}>(props => {
  const {
    sortAscending = false,
    sorted = false,
    onClick,
    borderClassName,
    children,
  } = props
  const styles = {
    base: css`
      position: relative; // anchor for sort icon container
      height: auto;
      text-align: left;
      background-color: ${TABLE_TH_BACKGROUND_COLOR};
      border-bottom: 1px solid ${TABLE_BORDER_COLOR};
      font-weight: normal;
      vertical-align: middle;
      padding: 0 4px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 14px;

      &:hover {
        background-color: ${TABLE_TH_HOVER_COLOR};
      }
    `,
    div: css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      line-height: 120%;
    `,
  }

  return (
    <th className={cx(styles.base, borderClassName)} onClick={onClick}>
      <div className={styles.div}>{children}</div>
      {sorted && (
        <SortIconContainer>
          <SortIcon sortAscending={sortAscending} />
        </SortIconContainer>
      )}
    </th>
  )
})
