import { Unlock } from "lucide-react"

export function URLView({ url: _url }: { url: string }) {
  const url = new URL(_url)

  return (
    <div>
      <div css="flex items-center truncate" title={url.pathname}>
        <URLProtocol protocol={url.protocol} />
        <span>
          {url.host}
          {url.pathname}
          {url.search ? <QueryView query={url.search} /> : null}
        </span>
      </div>
    </div>
  )
}

function URLProtocol({ protocol }: { protocol: string }) {
  if (protocol === "https:") {
    return null
  }

  let icon: React.ReactNode

  if (protocol === "http:") {
    icon = <Unlock size={16} />
  }

  return (
    <>
      <span css="mr-1">{icon}</span>
      <span css="opacity-70">{protocol}</span>
      {"//"}
    </>
  )
}

export function QueryView({ query }: { query: string }) {
  return <span css="opacity-50">{query}</span>
}
