import "../debug"
import { css, cx } from "@emotion/css"
import { Filter, FolderOpen, PanelLeftClose, PanelLeftOpen } from "lucide-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { useDrop } from "react-aria"
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels"

import { Main, RootGrid, TopBar } from "~/components/Panels"
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Tab,
  Tabs,
} from "~/nextui"

import { Boundary } from "../components/ErrorOverlay"
import _har from "../sample.json"
import _ from "../tokens"
import { ThemeSwitch } from "../toolbar/DarkMode"
import { clearHandle, getSavedFileHandle, openFileWithHandle } from "../toolbar/file"
import { id } from "../utils"

import { EntityList } from "./components/EntryList"
import type { Har } from "./har"
import { BodyTab } from "./tabs/Body"
import { CookiesTab } from "./tabs/Cookies"
import { RequestTab } from "./tabs/Request"
import { ResponseTab } from "./tabs/Response"
import { TimelineTab } from "./tabs/Timeline"
import { HostTypeFilter } from "./toolbar/HostFilter"
import { MethodFilter } from "./toolbar/MethodFilter"
import { MimeTypeFilter } from "./toolbar/MimeTypeFilter"
import { StatusCodeFilter } from "./toolbar/StatusCodeFilter"
import { LocaleSwitch } from "~/toolbar/Locale"

enum ViewTab {
  Request = "request",
  Response = "response",
  Content = "content",
  Cookies = "cookies",
  Timeline = "timeline",
}

function App() {
  const [tab, setTab] = useState(ViewTab.Request)
  const rightTab = useRef<HTMLDivElement>(undefined!)

  const [showSidebar, setShowSidebar] = useState(true)
  const [savedHandle, setSavedHandle] = useState<FileSystemFileHandle>()

  const [selected, setSelected] = useState<string>()
  const [filename, setFilename] = useState<string>()
  const [har, setHar] = useState<Har | undefined>(
    process.env.NODE_ENV === "development" ? (_har as unknown as Har) : undefined
  )

  useEffect(() => {
    void getSavedFileHandle().then(setSavedHandle)
  }, [])

  const [filterStatus, setFilterStatus] = useState<string>()
  const [methodFilter, setMethodFilter] = useState<string[]>([])
  const [mimeFilter, setMimeFilter] = useState<string[]>([])
  const [excludeHost, setExcludeHost] = useState<string[]>([])
  const [excludeMimeType, setExcludeMimeType] = useState<string[]>([])
  const [hostFilter, setHostFilter] = useState<string[]>([])

  Object.assign(globalThis, {
    har,
    saveState() {
      localStorage.setItem(
        "viewState",
        JSON.stringify({
          filterStatus,
          methodFilter,
          mimeFilter,
          hostFilter,
          excludeHost,
          excludeMimeType,
        })
      )
    },
    restoreState() {
      const {
        filterStatus,
        methodFilter,
        mimeFilter,
        hostFilter,
        excludeMimeType,
        excludeHost,
      } = JSON.parse(localStorage.getItem("viewState")!)
      setFilterStatus(filterStatus)
      setMethodFilter(methodFilter)
      setMimeFilter(mimeFilter)
      setHostFilter(hostFilter)
      setExcludeHost(excludeHost)
      setExcludeMimeType(excludeMimeType)
    },
  })

  const activeEntry = har?.log.entries.find(entry => id(entry) === selected)

  const entries = useMemo(() => {
    let list = har?.log.entries ?? []

    if (filterStatus) {
      list = list.filter(e => e.response.status.toString()[0] === filterStatus)
    }
    if (mimeFilter.length) {
      const set = new Set(mimeFilter)
      list = list.filter(
        e =>
          e.response.content.mimeType &&
          set.has(e.response.content.mimeType.split(";")[0])
      )
    }
    if (excludeMimeType.length) {
      const set = new Set(excludeMimeType)
      list = list.filter(
        e =>
          !e.response.content.mimeType ||
          !set.has(e.response.content.mimeType.split(";")[0])
      )
    }

    if (hostFilter.length) {
      const set = new Set(hostFilter)
      list = list.filter(e => set.has(new URL(e.request.url).host))
    }
    if (excludeHost.length) {
      const set = new Set(excludeHost)
      list = list.filter(e => !set.has(new URL(e.request.url).host))
    }
    if (methodFilter.length) {
      const set = new Set(methodFilter)
      list = list.filter(e => set.has(e.request.method))
    }

    return list
  }, [
    har,
    filterStatus,
    mimeFilter,
    hostFilter,
    methodFilter,
    excludeMimeType,
    excludeHost,
  ])

  async function open(file: File) {
    setFilename(file.name)
    try {
      const json = JSON.parse(await file.text()) as Har
      if (!Array.isArray(json?.log?.entries)) {
        alert("This file is not a valid HAR file.")
        return
      }
      setHar(json)
      setShowSidebar(true)
    } catch (e) {
      if (e instanceof SyntaxError) {
        alert(e.message)
      }
    }
  }

  const dropRef = useRef<HTMLDivElement>(null)
  const { dropProps, isDropTarget } = useDrop({
    ref: dropRef,
    async onDrop(e) {
      const item = e.items.find(x => x.kind === "file")
      if (!item) return

      await open(await item.getFile())
    },
  })

  const rightPanel = (
    <>
      <div css="flex">
        <Tabs
          css="m-2 inline-flex grow"
          selectedKey={tab}
          onSelectionChange={t => setTab(t as ViewTab)}
        >
          <Tab key={ViewTab.Request} title={<TabTitle name="Request" />} />
          <Tab key={ViewTab.Response} title={<TabTitle name="Response" />} />
          <Tab key={ViewTab.Content} title={<TabTitle name="Content" />} />
          <Tab key={ViewTab.Cookies} title={<TabTitle name="Cookies" />} />
          <Tab key={ViewTab.Timeline} title={<TabTitle name="Timeline" />} />
        </Tabs>

        <div ref={rightTab} />
      </div>
      <div css="grow overflow-scroll">
        <Boundary>
          {tab === ViewTab.Request && <RequestTab entry={activeEntry} />}
          {tab === ViewTab.Response && <ResponseTab entry={activeEntry} />}
          {tab === ViewTab.Content && (
            <BodyTab entry={activeEntry} rightSlot={rightTab} />
          )}
          {tab === ViewTab.Cookies && <CookiesTab entry={activeEntry} />}
          {tab === ViewTab.Timeline && <TimelineTab entry={activeEntry} />}
        </Boundary>
      </div>
    </>
  )

  const PanelIcon = showSidebar ? PanelLeftClose : PanelLeftOpen

  return (
    <div ref={dropRef} {...dropProps}>
      <title>{filename ?? "HAR Viewer"}</title>
      {isDropTarget && (
        <div
          className={css`
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            color: #fff;
            z-index: 1000;
            align-items: center;
            justify-content: center;
          `}
        >
          <div>Drop your HAR file here…</div>
        </div>
      )}
      <div className={RootGrid}>
        <div className={TopBar}>
          <div className="mx-1 my-0.5 flex items-center">
            <div css="flex grow items-center gap-1">
              <Button
                aria-label="Menu"
                css="-mr-2 ml-1 h-8 min-w-0 rounded-md bg-transparent px-2 hover:bg-gray-300 dark:hover:bg-gray-800"
                startContent={<PanelIcon css="opacity-60" size={19} />}
                title={showSidebar ? "Hide sidebar" : "Show sidebar"}
                onPress={() => setShowSidebar(!showSidebar)}
              />

              <Button
                aria-label="Open file"
                css="h-8 rounded-md bg-transparent py-1 hover:bg-gray-300 dark:hover:bg-gray-800"
                startContent={<FolderOpen css="opacity-60" size={16} />}
                onPress={() => openFileWithHandle().then(open)}
              >
                Open
              </Button>

              <hr
                className={css`
                  border: none;
                  border-right: 0.5px solid light-dark(#d6d6d6, #444);
                  height: 2em;
                  margin-right: 0.5em;
                `}
              />

              {false && <Filter size={16} />}
              <div css="ml-1 w-28">
                <MethodFilter har={har} value={methodFilter} onChange={setMethodFilter} />
              </div>
              <div css="w-32">
                <StatusCodeFilter
                  har={har}
                  value={filterStatus}
                  onChange={setFilterStatus}
                />
              </div>
              <div css="w-52">
                <HostTypeFilter har={har} value={hostFilter} onChange={setHostFilter} />
              </div>
              <div css="w-52">
                <HostTypeFilter
                  har={har}
                  placeholder="Exclude host"
                  value={excludeHost}
                  onChange={setExcludeHost}
                />
              </div>
              <div css="w-48">
                <MimeTypeFilter har={har} value={mimeFilter} onChange={setMimeFilter} />
              </div>
              <div css="w-48">
                <MimeTypeFilter
                  har={har}
                  placeholder="Exclude Mime Type"
                  value={excludeMimeType}
                  onChange={setExcludeMimeType}
                />
              </div>
            </div>

            <div css="flex items-center gap-1">
              <Dropdown>
                <DropdownTrigger>
                  <Button css="min-w-0 max-w-[200px] px-2" variant="light">
                    {filename ?? "Saved"}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu aria-label="Static Actions">
                  <DropdownItem key="delete" onPress={() => clearHandle()}>
                    Clear all
                  </DropdownItem>
                  {savedHandle != null ? (
                    <DropdownItem
                      key="restore"
                      onPress={async () => {
                        await savedHandle.requestPermission({ mode: "read" })
                        await open(await savedHandle.getFile())
                      }}
                    >
                      {truncate(savedHandle.name, 20)}
                    </DropdownItem>
                  ) : (
                    null!
                  )}
                </DropdownMenu>
              </Dropdown>
              <ThemeSwitch />
              <LocaleSwitch />
            </div>
          </div>
        </div>

        {!showSidebar ? (
          <div
            className={css`
              width: 100vw;
            `}
          >
            {rightPanel}
          </div>
        ) : (
          <PanelGroup autoSaveId="har-view" className={Main} direction="horizontal">
            <Panel css="bg-default-50 !overflow-y-scroll" defaultSize={40}>
              {har ? (
                <Boundary>
                  <EntityList
                    entries={entries}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </Boundary>
              ) : (
                <div css="mx-6 my-4 italic opacity-50">No entries available.</div>
              )}
            </Panel>

            <PanelResizeHandle
              className={css`
                background-color: light-dark(#eee, #333);
                width: 0.5px;
              `}
            />
            <Panel css="flex flex-col !overflow-y-scroll">{rightPanel}</Panel>
          </PanelGroup>
        )}

        {activeEntry != null && (
          <div
            className={cx(
              css`
                border-top: 0.5px solid light-dark(#bfb8b1, #070707);
                grid-area: bottom-bar;
                padding: 6px 10px;
              `,
              "bg-default-100 truncate text-sm opacity-70"
            )}
          >
            {entries.length} {entries.length < 2 ? "request" : "requests"}.{" "}
            {activeEntry.request.url}
          </div>
        )}
      </div>
    </div>
  )
}

function truncate(str: string, len: number) {
  return str.length > len ? str.slice(0, len) + "…" : str
}

function TabTitle({ name }: { name: React.ReactNode }) {
  return <div css="flex items-center gap-1">{name}</div>
}

export default App
