import { tw } from "@aet/tailwind/µ"
import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { Suspense, lazy, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import { ErrorBoundary } from "react-error-boundary"

import { Card, Tab, Tabs } from "nextui"
import type { Content, Entry } from "~/har/har"
import { useOctetStream } from "~/hooks/useOctetStream"
import { monaco, useMonacoTheme } from "~/monaco"
import { id } from "~/utils"

import { DOMView } from "./DOMView"
import { BodyHeader } from "./Header"
import { JSONView } from "./JSONView"
import { RawEditor } from "./Raw"

const QuickType = lazy(() => import("../QuickType"))
const PDFView = lazy(() => import("../../components/PDF"))

const enum ViewType {
  Object = "object",
  Editor = "raw",
  Third = "extra",
}

function ContentView({
  url,
  content,
  rightSlot,
}: {
  url: string
  content: Content
  rightSlot: React.RefObject<HTMLDivElement>
}) {
  const monacoTheme = useMonacoTheme()
  const [viewTab, setViewTab] = useState<ViewType>(ViewType.Object)

  const mimeType = content.mimeType ?? ""
  const isJSON =
    /application\/(\w+\+)?json/.test(mimeType) || mimeType.includes("text/json")
  const isHTML = mimeType.includes("text/html")
  const isOctet = mimeType.includes("application/octet-stream")

  const text = useMemo(
    () => (content.encoding === "base64" ? atob(content.text!) : content.text) ?? "",
    [content]
  )

  const { fileType } = useOctetStream(text, !isOctet)

  const isImage = mimeType.includes("image/") || fileType?.mime.startsWith("image")
  const isPDF = mimeType.includes("application/pdf")

  let body: React.ReactNode
  if (viewTab === ViewType.Editor) {
    body = (
      <div>
        <RawEditor
          readOnly
          path={`${id(content)}.${getExtension(mimeType)}`}
          value={text}
        />
      </div>
    )
  } else if (viewTab === ViewType.Third) {
    body = isJSON ? (
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <Suspense fallback={<div>Loading...</div>}>
          <QuickType json={text} />
        </Suspense>
      </ErrorBoundary>
    ) : isHTML ? (
      <div css="size-full p-2">
        <iframe
          css="size-full rounded-lg bg-content1 p-2 shadow-small"
          sandbox=""
          srcDoc={text}
          title="HTML"
        />
      </div>
    ) : (
      <div>Type is only available for JSON content.</div>
    )
  } else if (isJSON) {
    body = (
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <JSONView css="overflow-y-scroll text-sm" data={text} name="Response" />
      </ErrorBoundary>
    )
  } else if (isHTML) {
    body = <DOMView css="overflow-y-scroll" data={text} />
  } else if (isImage) {
    body = (
      <Card
        css="m-6 overflow-scroll"
        className={css`
          background: rgba(50, 50, 50, 0.01)
            repeating-conic-gradient(rgba(50, 50, 50, 0.05) 0% 25%, transparent 0% 50%)
            50% / 10px 10px;
        `}
      >
        <img
          alt="content"
          className={css({ maxHeight: "calc(100% - 60px)" })}
          css="m-4 object-none"
          src={`data:${mimeType};${content.encoding},${encodeURIComponent(content.text!)}`}
        />
      </Card>
    )
  } else if (isPDF) {
    body = (
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <Suspense fallback={<div>Loading...</div>}>
          <div css="relative size-full p-2">
            <PDFView
              css="size-full overflow-scroll rounded-lg bg-content1 shadow-small"
              file={`data:${mimeType};${content.encoding},${content.text}`}
            />
          </div>
        </Suspense>
      </ErrorBoundary>
    )
  } else {
    body = (
      <Raw
        css="mx-2 whitespace-pre-wrap text-sm"
        ref={el => {
          if (el?.childNodes.length === 1 && text.length < 30000) {
            void monaco.editor.colorizeElement(el, { theme: monacoTheme, mimeType })
          }
        }}
      >
        {text}
      </Raw>
    )
  }

  return (
    <div
      css="m-2 grid"
      className={css`
        height: calc(100% - 15px);
        grid-template-rows: auto minmax(0, 1fr);
      `}
    >
      {createPortal(
        <Tabs
          css="mr-4 mt-2"
          selectedKey={viewTab}
          onSelectionChange={t => setViewTab(t as ViewType)}
        >
          <Tab key={ViewType.Object} title="Inspect" />
          <Tab key={ViewType.Editor} title="Editor" />
          <Tab
            isDisabled={!isJSON && !isHTML}
            key={ViewType.Third}
            title={isJSON ? "Type" : "Preview"}
          />
        </Tabs>,
        rightSlot.current
      )}

      <BodyHeader content={content} fileType={fileType} url={url} />
      {body}
    </div>
  )
}

function getExtension(mimeType: string) {
  if (mimeType.includes("json")) {
    return ".json"
  }

  if (mimeType.includes("html")) {
    return ".html"
  }

  if (mimeType.includes("xml")) {
    return ".xml"
  }

  if (mimeType.includes("javascript")) {
    return ".js"
  }

  // css:
  if (mimeType.includes("css")) {
    return ".css"
  }

  return ".txt"
}

const Raw = styled.pre(tw`m-2 mx-3 overflow-y-scroll text-wrap break-words`)

export function BodyTab({
  entry,
  ...rest
}: {
  entry?: Entry
  rightSlot: React.RefObject<HTMLDivElement>
}) {
  if (!entry) {
    return null
  }

  const { response, request } = entry
  if (!response.content.text) {
    return (
      <div css="m-3">
        <h3 css="mb-2 ml-1 text-sm font-medium uppercase tracking-widest opacity-50">
          Content
        </h3>
        <Card css="h-48 justify-center p-2" shadow="sm">
          <div css="text-center align-middle text-foreground-400">
            No content to display.
          </div>
        </Card>
      </div>
    )
  }

  return <ContentView content={response.content} url={request.url} {...rest} />
}
