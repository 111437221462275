import { Suspense, useState } from "react"
import { Button } from "react-aria-components"
import { ErrorBoundary } from "react-error-boundary"

import { KVTable } from "~/components/KVTable"
import { Card } from "~/nextui"

import type { PostData } from "../har"

import { JSONView } from "./Body/JSONView"
import { RawEditor } from "./Body/Raw"

export function PostData({ postData }: { postData?: PostData }) {
  const [useJSONView, setUseJSONView] = useState(true)

  if (!postData) {
    return null
  }

  const title = mimeTypeLabel(postData.mimeType)
  if (title === "JSON") {
    return (
      <div css="mb-4">
        <h3 css="mb-2 ml-1 text-sm font-medium uppercase tracking-widest">
          {useJSONView ? (
            <span css="cursor-default opacity-50">{title}</span>
          ) : (
            <Button
              css="cursor-pointer uppercase opacity-40 hover:underline"
              onPress={() => setUseJSONView(true)}
            >
              JSON
            </Button>
          )}
          <span css="opacity-40"> / </span>
          {useJSONView ? (
            <Button
              css="cursor-pointer uppercase opacity-40 hover:underline"
              onPress={() => setUseJSONView(false)}
            >
              Raw
            </Button>
          ) : (
            <span css="cursor-default opacity-50">Raw</span>
          )}
        </h3>
        <Card css="px-3 py-4">
          {useJSONView ? (
            <ErrorBoundary fallback={<div>Something went wrong</div>}>
              <Suspense fallback={<div>Loading...</div>}>
                <JSONView minimal css="overflow-y-scroll text-sm" data={postData.text} />
              </Suspense>
            </ErrorBoundary>
          ) : (
            <RawEditor
              keepCurrentModel
              readOnly
              css="min-h-20"
              language="json"
              path="request.json"
              value={postData.text}
              options={{
                lineNumbers: "off",
              }}
            />
          )}
        </Card>
      </div>
    )
  }

  return (
    <KVTable
      sort
      className="mb-6"
      title={title || "Post data"}
      values={postData.params ?? [{ name: "Text", value: postData.text }]}
    />
  )
}

function mimeTypeLabel(mimeType: string) {
  if (mimeType.startsWith("multipart/form-data")) {
    return "Multipart form data"
  } else if (mimeType.startsWith("application/x-www-form-urlencoded")) {
    return "Form data"
  } else if (mimeType.startsWith("application/json")) {
    return "JSON"
  }

  return mimeType
}
