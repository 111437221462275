import { css, cx } from "@emotion/css"
import {
  Children,
  createContext,
  memo,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { usePress } from "react-aria"

import { BASE_COLOR, TREENODE_PADDING_LEFT, lightDark } from "./tokens"

type ExpandedPath = Record<string, boolean>
type UseState<S> = readonly [S, React.Dispatch<React.SetStateAction<S>>]

const ExpandedPathsContext = createContext<UseState<ExpandedPath>>([{}, () => {}])

const DEFAULT_ROOT_PATH = "$"
const WILDCARD = "*"
const ARROW_FONT_SIZE = "1.1em"

function hasChildNodes<T>(data: T, dataIterator: DataIterator<T>) {
  return !dataIterator(data).next().done
}

const wildcardPathsFromLevel = (level: number): string[] =>
  // i is depth
  Array.from({ length: level }, (_, i) =>
    [DEFAULT_ROOT_PATH].concat(Array.from({ length: i }, () => "*")).join(".")
  )

function getExpandedPaths<T>(
  data: T,
  dataIterator: DataIterator<T>,
  expandPaths: string[],
  expandLevel: number,
  prevExpandedPaths: ExpandedPath
) {
  const wildcardPaths = ([] as string[])
    .concat(wildcardPathsFromLevel(expandLevel))
    .concat(expandPaths)
    .filter(path => typeof path === "string") // could be undefined

  const expandedPaths: string[] = []
  for (const wildcardPath of wildcardPaths) {
    const keyPaths = wildcardPath.split(".")
    const populatePaths = (curData: T, curPath: string, depth: number) => {
      if (depth === keyPaths.length) {
        expandedPaths.push(curPath)
        return
      }
      const key = keyPaths[depth]
      if (depth === 0) {
        if (
          hasChildNodes(curData, dataIterator) &&
          (key === DEFAULT_ROOT_PATH || key === WILDCARD)
        ) {
          populatePaths(curData, DEFAULT_ROOT_PATH, depth + 1)
        }
      } else if (key === WILDCARD) {
        for (const { name, data } of dataIterator(curData)) {
          if (hasChildNodes(data, dataIterator)) {
            populatePaths(data, `${curPath}.${name}`, depth + 1)
          }
        }
      } else {
        const value = (curData as any)[key]
        if (hasChildNodes(value, dataIterator)) {
          populatePaths(value, `${curPath}.${key}`, depth + 1)
        }
      }
    }

    populatePaths(data, "", 0)
  }

  return expandedPaths.reduce(
    (obj, path) => {
      obj[path] = true
      return obj
    },
    { ...prevExpandedPaths }
  )
}

const Arrow = memo<{
  expanded?: boolean
}>(({ expanded }) => (
  <span
    className={css({
      color: lightDark("#6e6e6e", "#919191"),
      display: "inline-block",
      // lineHeight: '14px',
      fontSize: ARROW_FONT_SIZE,
      marginRight: "6px",
      marginLeft: "-2px",
      userSelect: "none",
      transform: expanded ? "rotateZ(90deg)" : "rotateZ(0deg)",
    })}
  >
    ▶
  </span>
))

const defaultTreeNodeRenderer: NodeRenderer = ({ name }: any) => <span>{name}</span>

const TreeNode = memo<{
  name?: string
  data?: any
  expanded?: boolean
  shouldShowArrow?: boolean
  shouldShowPlaceholder?: boolean
  nodeRenderer?: any
  onClick?: () => void
  children?: React.ReactNode
  title?: string
}>(props => {
  const {
    expanded = true,
    onClick,
    children,
    nodeRenderer: NodeRenderer = defaultTreeNodeRenderer,
    title,
    shouldShowArrow = false,
    shouldShowPlaceholder = true,
    ...rest
  } = props

  return (
    <li
      aria-expanded={expanded}
      aria-selected={false}
      role="treeitem"
      title={title}
      className={cx(
        css({
          color: BASE_COLOR,
          lineHeight: 1.3,
          cursor: "default",
          boxSizing: "border-box",
          listStyle: "none",
        })
      )}
      {...rest}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyUp={e => {
          if (e.key === "Enter" || e.key === " ") {
            onClick?.()
          }
        }}
      >
        {shouldShowArrow || Children.count(children) > 0 ? (
          <Arrow expanded={expanded} />
        ) : (
          shouldShowPlaceholder && (
            <span
              className={css`
                white-space: pre;
                font-size: ${ARROW_FONT_SIZE};
                margin-right: 3px;
                user-select: none;
              `}
            >
              &nbsp;
            </span>
          )
        )}
        <NodeRenderer {...props} />
      </div>

      {expanded && (
        <ol
          className={css({
            margin: 0, // reset user-agent style
            paddingLeft: TREENODE_PADDING_LEFT,
          })}
        >
          {children}
        </ol>
      )}
    </li>
  )
})

export type DataIterator<T> = (
  data: T
) => IterableIterator<{ name: string; data: T; isNonEnumerable?: boolean }>

export type NodeRenderer = React.FC<{
  depth: number
  name: string
  isNonEnumerable?: boolean
  /** If true, just render a close tag */
  isCloseTag?: boolean
  /** The DOM Node */
  data: Node
  /** Whether the DOM node has been expanded. */
  expanded?: boolean
}>

interface SharedTreeNodeProps {
  data: any
  dataIterator: DataIterator<any>
  name: string
  nodeRenderer: NodeRenderer
}

interface ConnectedTreeNodeProps extends SharedTreeNodeProps {
  depth: number
  path: string
}

export interface TreeViewProps extends SharedTreeNodeProps {
  expandLevel: number
  expandPaths: string[]
}

const ConnectedTreeNode = memo<ConnectedTreeNodeProps>(props => {
  const { data, dataIterator, path, depth, nodeRenderer } = props
  const [expandedPaths, setExpandedPaths] = useContext(ExpandedPathsContext)
  const nodeHasChildNodes = hasChildNodes(data, dataIterator)
  const expanded = !!expandedPaths[path]

  const handleClick = useCallback(
    () =>
      nodeHasChildNodes &&
      setExpandedPaths(prevExpandedPaths => ({
        ...prevExpandedPaths,
        [path]: !expanded,
      })),
    [nodeHasChildNodes, setExpandedPaths, path, expanded]
  )

  return (
    <TreeNode
      data-has-child-nodes={nodeHasChildNodes}
      onClick={handleClick}
      expanded={expanded}
      // show arrow anyway even if not expanded and not rendering children
      shouldShowArrow={nodeHasChildNodes}
      // show placeholder only for non root nodes
      shouldShowPlaceholder={depth > 0}
      // Render a node from name and data (or possibly other props like isNonEnumerable)
      {...props}
    >
      {
        // only render if the node is expanded
        expanded
          ? [...dataIterator(data)].map(({ name, data, ...renderNodeProps }) => (
              <ConnectedTreeNode
                data={data}
                dataIterator={dataIterator}
                depth={depth + 1}
                key={name}
                name={name}
                nodeRenderer={nodeRenderer}
                path={`${path}.${name}`}
                {...renderNodeProps}
              />
            ))
          : null
      }
    </TreeNode>
  )
})

export const TreeView = memo<TreeViewProps>(
  ({ name, data, dataIterator, nodeRenderer, expandPaths, expandLevel }) => {
    const [expanded, setExpandedPaths] = useState<ExpandedPath>({})

    useLayoutEffect(
      () =>
        setExpandedPaths(prevExpandedPaths =>
          getExpandedPaths(
            data,
            dataIterator,
            expandPaths,
            expandLevel,
            prevExpandedPaths
          )
        ),
      [data, dataIterator, expandPaths, expandLevel]
    )

    const context = useMemo(
      () => [expanded, setExpandedPaths] as const,
      [expanded, setExpandedPaths]
    )

    return (
      <ExpandedPathsContext.Provider value={context}>
        <ol
          role="tree"
          className={css`
            padding: 0;
            margin: 0;
            list-style-type: none;
          `}
        >
          <ConnectedTreeNode
            data={data}
            dataIterator={dataIterator}
            depth={0}
            name={name}
            nodeRenderer={nodeRenderer}
            path={DEFAULT_ROOT_PATH}
          />
        </ol>
      </ExpandedPathsContext.Provider>
    )
  }
)
