import type { Selection } from "react-aria-components"

const _id = new WeakMap<object, number>()
let _idCounter = 1

export function id(obj: object) {
  if (!_id.has(obj)) {
    _id.set(obj, _idCounter++)
  }
  return String(_id.get(obj)!)
}

export function getSelectionKey(selection: Selection) {
  return (selection as unknown as { currentKey: string }).currentKey
}
