import { useMemo } from "react"

import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "nextui"
import _ from "~/tokens"

import type { Entry } from "../har"

export function CookiesTab({ entry }: { entry?: Entry }) {
  const response = entry?.response

  const sorted = useMemo(
    () => response?.cookies.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [response?.cookies]
  )

  if (!entry) {
    return null
  }

  return (
    <div css="m-3">
      <h3 css="mb-2 ml-1 text-sm font-medium uppercase tracking-widest opacity-50">
        Cookies
      </h3>
      <Table hideHeader isStriped aria-label="Headers">
        <TableHeader>
          <TableColumn>Title</TableColumn>
          <TableColumn>Value</TableColumn>
        </TableHeader>
        <TableBody emptyContent={"No cookie to display."}>
          {sorted.map(cookie => (
            <TableRow key={cookie.name + cookie.value}>
              <TableCell css="w-48 align-top !font-medium">{cookie.name}</TableCell>
              <TableCell css="max-w-64 text-wrap break-words" height={35}>
                {cookie.value === "" ? (
                  <span css="italic opacity-40">Empty</span>
                ) : (
                  cookie.value
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
